<ng-template #uploadpo let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title"><b>Purchase Order Uploader</b></h4>
		<h6 *ngIf="poReviewReady">File count : ({{files.length}})</h6>
		<button mat-stroked-button class="pomodalbtn position-absolute r-70" (click)="$event.preventDefault();toggleUploadHelp()" (keydown.enter)="$event.preventDefault();">Help</button>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>

	</div>
	<div class="modal-body pomodalbody">
		<ng-container *ngIf="!poHelp">
			<ngx-dropzone (change)="onSelect($event)" class="dropzone" *ngIf="!processing_recs && !poReviewReady">
				<ngx-dropzone-label class="dropzone-label">Drop file, or click to upload!</ngx-dropzone-label>
				<ngx-dropzone-preview *ngFor="let f of files;" [removable]="true" (removed)="onRemove(f)">
					<ngx-dropzone-label class="overflow-auto ">{{ f.name }}</ngx-dropzone-label>
				</ngx-dropzone-preview>
			</ngx-dropzone>
			<div class="table table-responsive text-center" *ngIf="processing_recs">
				<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
					<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			</div>
			<mat-tab-group *ngIf="poReviewReady">
				<mat-tab *ngIf="poDataSource['good']">
					<ng-template mat-tab-label>
						<span>Recognized&nbsp;<mat-icon class="text-success align-middle">done</mat-icon></span>
					</ng-template>
					<table mat-table [dataSource]="poDataSource['good']" class="mat-elevation-z8 demo-table" *ngIf="poReviewReady" class="po-table">
						<ng-container matColumnDef="stockid">
							<th mat-header-cell *matHeaderCellDef> Stock ID </th>
							<td mat-cell *matCellDef="let element"> {{element.stockid}} </td>
						</ng-container>

						<ng-container matColumnDef="vendor">
							<th mat-header-cell *matHeaderCellDef> Recommendation Source </th>
							<td mat-cell *matCellDef="let element" class="text-center "> {{element.vendor}} </td>
						</ng-container>

						<ng-container matColumnDef="description">
							<th mat-header-cell *matHeaderCellDef> Description </th>
							<td mat-cell *matCellDef="let element"> {{element.description}} </td>
						</ng-container>

						<ng-container matColumnDef="manufacturer">
							<th mat-header-cell *matHeaderCellDef> MFG </th>
							<td mat-cell *matCellDef="let element"> {{element.manufacturer}} </td>
						</ng-container>

						<ng-container matColumnDef="line">
							<th mat-header-cell *matHeaderCellDef> Line </th>
							<td mat-cell *matCellDef="let element"> {{element.line}} </td>
						</ng-container>

						<ng-container matColumnDef="min">
							<th mat-header-cell *matHeaderCellDef> Min </th>
							<td mat-cell *matCellDef="let element"> {{element.min}} </td>
						</ng-container>

						<ng-container matColumnDef="max">
							<th mat-header-cell *matHeaderCellDef> Max </th>
							<td mat-cell *matCellDef="let element"> {{element.max}} </td>
						</ng-container>

						<ng-container matColumnDef="qty">
							<th mat-header-cell *matHeaderCellDef> Order Quantity </th>
							<td mat-cell *matCellDef="let element" class="text-center "> {{element.quantity}} </td>
						</ng-container>

						<ng-container matColumnDef="price">
							<th mat-header-cell *matHeaderCellDef> Price </th>
							<td mat-cell *matCellDef="let element"> {{element.price | currency}} </td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="poUploadColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: poUploadColumns;"></tr>
					</table>
				</mat-tab>
				<mat-tab *ngIf="poDataSource['bad']">
					<ng-template mat-tab-label>
						<span>Unrecognized&nbsp;<mat-icon class="text-danger align-middle">announcement</mat-icon></span>
					</ng-template>
					<table mat-table [dataSource]="poDataSource['bad']" class="mat-elevation-z8 demo-table" *ngIf="poReviewReady" class="po-table">
						<ng-container matColumnDef="stockid">
							<th mat-header-cell *matHeaderCellDef> Stock ID </th>
							<td mat-cell *matCellDef="let element"> {{element.stockid}} </td>
						</ng-container>

						<ng-container matColumnDef="vendor">
							<th mat-header-cell *matHeaderCellDef> Reccomendation Source </th>
							<td mat-cell *matCellDef="let element" class="text-center "> {{element.vendor}} </td>
						</ng-container>

						<ng-container matColumnDef="description">
							<th mat-header-cell *matHeaderCellDef> Description </th>
							<td mat-cell *matCellDef="let element"> {{element.description}} </td>
						</ng-container>

						<ng-container matColumnDef="manufacturer">
							<th mat-header-cell *matHeaderCellDef> MFG </th>
							<td mat-cell *matCellDef="let element"> {{element.manufacturer}} </td>
						</ng-container>

						<ng-container matColumnDef="line">
							<th mat-header-cell *matHeaderCellDef> Line </th>
							<td mat-cell *matCellDef="let element"> {{element.line}} </td>
						</ng-container>

						<ng-container matColumnDef="min">
							<th mat-header-cell *matHeaderCellDef> Min </th>
							<td mat-cell *matCellDef="let element"> {{element.min}} </td>
						</ng-container>

						<ng-container matColumnDef="max">
							<th mat-header-cell *matHeaderCellDef> Max </th>
							<td mat-cell *matCellDef="let element"> {{element.max}} </td>
						</ng-container>

						<ng-container matColumnDef="qty">
							<th mat-header-cell *matHeaderCellDef> Order Quantity </th>
							<td mat-cell *matCellDef="let element" class="text-center "> {{element.quantity}} </td>
						</ng-container>

						<ng-container matColumnDef="price">
							<th mat-header-cell *matHeaderCellDef> Price </th>
							<td mat-cell *matCellDef="let element"> {{element.price | currency}} </td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="poUploadColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: poUploadColumns;"></tr>
					</table>
				</mat-tab>
			</mat-tab-group>
		</ng-container>
		<ng-container *ngIf="poHelp">
			<div class="row">
				<div class="col">
					<h4><b>Headers</b></h4>
					<h6 class="warning ">(These are required to be exact matches, order of columns isn't important)</h6>
					<ol>
						<li>stockid</li>
						<li>qty_purchase</li>
						<li>price</li>
					</ol>
					<hr>
					<h4><b>Notes</b></h4>
					<ul style="max-height: 150px;" class="overflow-auto ">
						<li>
							<p>Each stockid is validated within the system. If a match is found it will be shown under the recognized tab, otherwise it will be under the unrecognized tab.</p>
						</li>
						<li>
							<p>Only recognized skus will be pushed through to PO review.</p>
						</li>
						<li>
							<p>Multiple files can be uploaded at once and will be merged into a single purchase order.</p>
						</li>
						<li>
							<p>When price is left blank, the system will use last PO price and if that's blank it will use primary supplier price.</p>
						</li>
						<li>
							<p>Lines with a qty_purchase of 0 are ignored.</p>
						</li>
						<li>
							<p>Files can be removed during the upload step by hovering over the title of the file and clicking the <mat-icon>cancel</mat-icon> button.</p>
						</li>
					</ul>
				</div>
				<mat-divider vertical="true"></mat-divider>
				<div class="col">
					<h4><b>Row Data</b></h4>
					<h6>(Stockids are validated within in the system)</h6>
					<ol>
						<li>
							Stock ID you are trying to purchase as it appears in the system.
							<ul>
								<li>TOY-243450 <mat-icon>done</mat-icon>
								</li>
								<li>"TOY-243450" <span style="color: red;">(quotes)</span></li>
							</ul>
						</li>
						<li>
							Amount to Order.
							<ul>
								<li>4 <mat-icon>done</mat-icon>
								</li>
								<li>four <span style="color: red;">(non-numeric value)</span></li>
							</ul>
						</li>
						<li>
							Price of a single tire with no commas.
							<ul>
								<li>78.92<mat-icon>done</mat-icon>
								</li>
								<li>1,274.22 <span style="color: red;">(comma)</span></li>
							</ul>
						</li>
					</ol>
					<hr>
					<h4>PLL# vs MFG#</h4>
					<ul>
						<li>
							<ng-container *ngIf="config.env.package == 'tires'"> PLL </ng-container>
							<ng-container *ngIf="config.env.package != 'tires'"> Internal </ng-container>
							will search on internal stockid.
							<ul>
								<li>"CON-1234589128"</li>
							</ul>
						</li>
						<li>
							MFG will search on internal MFG part #.
							<ul>
								<li>"1234589128"</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>

		</ng-container>
	</div>
	<div class="modal-footer pomodalfooter" [ngClass]="upload_error ? 'p-5' : ''">
		<div class="pomodalvendors row">
			<div>
				<span>Selected Vendor</span>
				<hr class="p-0 m-0">
				<h4><b><span [ngClass]="vendors_detected != false && customer.supplierid != vendors_detected ? 'warning' : ''">{{customer.supplierid}}</span> : {{customer.suppname}}</b></h4>
			</div>
			<div *ngIf="vendors_detected">
				<span>Detected</span>
				<hr class="p-0 m-0">
				<h4><span [ngClass]="customer.supplierid != vendors_detected ? 'warning' : ''">{{vendors_detected}}</span></h4>
			</div>
			<div *ngIf="poReviewReady">
				<span>Recognized</span>
				<hr class="p-0 m-0">
				<h4>Units: {{uploadPodata['units']['good']}} Lines: {{uploadPodata['lines']['good']}} <br></h4>
			</div>
			<div *ngIf="poReviewReady && poDataSource['bad']">
				<span>Unrecognized</span>
				<hr class="p-0 m-0">
				<h4>Units: {{uploadPodata['units']['bad']}} Lines: {{uploadPodata['lines']['bad']}} <br></h4>
			</div>
			<div *ngIf="upload_error" class="error_container">
				<span>Processing Error</span>
				<hr class="p-0 m-0 w-100 ">
				<h4 class='d-flex '>
					<ng-container *ngFor="let file of upload_error">
						<div *ngFor="let err of file | keyvalue" class="mr-5">
							{{err.key}} : <span class="warning" style="font-size: 16px;">{{err.value.message}} &nbsp;</span>
							<br style="height: 1px;">
							<span style="font-size: 12px;">{{err.value.file}}</span>
						</div>
					</ng-container>
				</h4>
			</div>
		</div>
		<div class="d-inline-block text-center" *ngIf="!poReviewReady">
			<span class="rounded-pill matchon">
				<ng-container *ngIf="config.env.package == 'tires'">PLL#</ng-container>
				<ng-container *ngIf="config.env.package != 'tires'">INTERNAL#</ng-container>
				&nbsp;
				<mat-slide-toggle color="primary" [checked]="fieldMatch" (change)="fieldMatch = fieldMatch ? false: true"></mat-slide-toggle>
				&nbsp;MFG#
			</span>
		</div>
		<button mat-stroked-button *ngIf="!poReviewReady" class="pomodalbtn" (click)="$event.preventDefault();uploadPo()" (keydown.enter)="$event.preventDefault();" [disabled]="files.length == 0">Process Recommendations</button>
		<button mat-stroked-button *ngIf="poReviewReady" class="pomodalbtn float-left " (click)="$event.preventDefault();resetUploadPo()" (keydown.enter)="$event.preventDefault();">Back</button>
		<button mat-stroked-button *ngIf="poReviewReady" class="pomodalbtn" (click)="$event.preventDefault();buildPO()" (keydown.enter)="$event.preventDefault();" [disabled]="!poDataSource['good']">Build PO</button>
	</div>
</ng-template>
<ng-template #mmmodal let-modal>
	<div class="modal-header">
		<h4 class="modal-title">{{ promo.itemdesc.stockid }} <span>{{promo.itemdesc.description }}</span></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="col-md-9">
				<div class="promo-item-description">
					<p class="stock-id">{{ promo.itemdesc.stockid }}</p>
					<p class="description">{{ promo.itemdesc.description }}</p>
				</div>
				<ul class="list-group">
					<ng-container *ngFor="let intro of promo.intros">
						<ng-container *ngIf="intro.type === '1' || intro.type === 1">
							<li class="list-group-item item-details-above">
								<div class="item-description">
									<p>{{ intro.stockid }} - {{ intro.description }} ({{ intro.qty }} @ {{ intro.price | currency }})</p>
								</div>
							</li>
						</ng-container>
					</ng-container>
				</ul>
			</div>
			<div class="col-md-3 text-right">
				<mat-form-field appearance="outline">
					<mat-label>Qty</mat-label>
					<span matPrefix>#</span>
					<input matInput type="number" [(ngModel)]="promo.qty" (input)="setParent($event, promo)" class="text-right">
				</mat-form-field>
				<button class="btn btn-primary mt-2" (click)="addMM(promo)" [disabled]="promo.error">Add To Order</button>
			</div>
		</div>

		<!-- {{ promo.descriptions | json }} <h4>PROMO {{ promo.description }} {{ ispromo.promo.price | currency }} </h4> -->
		<div class="promo-container" *ngIf="promo">
			<h5>Promo Items</h5>
			<div class="row m-0 p-0">
				<ng-container *ngFor="let intro of promo.intros">
					<div class="col-md-{{ 12  / getNumberOfNonType1Intros(promo.intros) }}" *ngIf="intro.type != '1'">
						<div class="promo-intro">
							<h4>{{ intro.name }} {{ intro.description }}</h4>
							<ng-container *ngIf="intro.type === '1'; else typeDefault">
								<div class="intro-details">
									<div class="quantity-info">
										{{ intro.item }} - {{ intro.description }} ({{ intro.qty }} @ {{ intro.price | currency }})
									</div>
								</div>
							</ng-container>
							<ng-template #typeDefault>
								<div class="intro-details" [ngClass]="{'error': intro.error}">
									<div class="quantity-info">
										{{ intro.price }} <span [ngClass]="{'zero-quantity': (updateMMQty(intro) == 0) }">{{ intro.dsptype }} {{ updateMMQty(intro) }} / {{ intro.floor }}</span> {{ intro.price }}
									</div>
								</div>
							</ng-template>
						</div>
						<ng-container *ngFor="let item of intro.items">
							<li class="list-group-item item-details">
								<div class="item-details-row">
									<div class="item-description">
										<p>{{ item.stockid }}</p>
										<p>{{ item.description }}</p>
									</div>
									<div class="item-quantity">
										<button mat-icon-button (click)="decrementmm(item, intro)">
											<mat-icon>remove</mat-icon>
										</button>
										<input matInput type="text" class="quantity-input" value="{{ item.quantity}}" (input)="qtyMM($event, item, intro)">
										<button mat-icon-button (click)="incrementmm(item, intro)">
											<mat-icon>add</mat-icon>
										</button>
									</div>
								</div>
							</li>
						</ng-container>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #itemHistoryRef let-modal>
	<div class="modal-header">
		<h4 class="modal-title">{{ selecteditem.stockid }} <span [innerHTML]="cleanAndFormatHTML(selecteditem.description)"></span></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<mat-tab-group>
			<mat-tab label="{{ customer.name }} Sales">
				<div class="row mt-3">
					<div class="col-12">

						<div class="row" *ngIf="!itemhistory.length">
							<div class="col-12 text-center">
								<h5>No Sales History</h5>
							</div>
						</div>
						<table class="table table-sm smaller-font" *ngIf="itemhistory.length">
							<tr>
								<th>Date</th>
								<th>Type</th>
								<th>Order#</th>
								<th>Qty</th>
								<th>Price</th>
								<th>Total</th>
							</tr>
							<tr *ngFor="let i of itemhistory">
								<td>{{ i.trandate }}</td>
								<td>{{ i.typename }}</td>
								<td>{{ i.order_ }}</td>
								<td>{{ i.quantity }}</td>
								<td>{{ i.price  | currency}}</td>
								<td>{{ i.price * i.quantity | currency }}</td>
							</tr>
						</table>
					</div>
				</div>
			</mat-tab>
			<mat-tab label="Purchases">
				<!-- (orderloaded)="selectItemLookup($event)" (orderinvoice)="invoiceOrder($event)" -->
				<h4>Open Purchase Orders</h4>
				<app-open-purchase-orders [item]="selecteditem.stockid"></app-open-purchase-orders>
				<mat-divider></mat-divider>
				<h4>Purchase History</h4>
				<table class="table table-sm" *ngIf="itemhistory.purchase">
					<thead>
						<tr>
							<th class="text-left w-20">SUPP</th>
							<th class="text-left w-20">PO</th>
							<th class="text-left w-20">DATE</th>
							<th class="text-center w-20">QTY</th>
							<th class="text-right w-20">COST</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let h of itemhistory.purchase">
							<td class="text-left"> {{h.suppname}}</td>
							<td class="text-left"> {{h.orderno}}</td>
							<td class="text-left"> {{h.orddate}}</td>
							<td class="text-center"> {{h.quantityord}}</td>
							<td class="text-right"> {{h.unitprice | currency}}</td>
						</tr>
					</tbody>
				</table>
			</mat-tab>
			<mat-tab label="Purchase Data">
				<div class="row m-0 p-0 mt-3">
					<ng-container *ngFor="let pdata of selecteditem.purchdata">
						<mat-card class="ml-auto mr-auto col-md-4 cpdata" [ngClass]="selecteditem.selected_po_option == pdata ? 'cpdata-selected': '' " (click)="setItemSelectedPoOption(selecteditem,pdata)">
							<mat-card-header>
								<mat-card-subtitle><span matBadge="{{ pdata.preferred_sort }}" matBadgeSize="small" matBadgeColor="accent"></span></mat-card-subtitle>
								<mat-card-title>{{ pdata.supplierid }} - {{ pdata.suppname }} </mat-card-title>
								<mat-card-title> {{ pdata.price | currency }}</mat-card-title>
							</mat-card-header>
							<mat-card-content>
								<h4>Inventory</h4>
								<ng-container *ngIf="pdata.remote">
									<ul class="entry-list p-0 m-0" *ngFor="let remote of pdata.remote">
										<li>
											<div class="spec-label">{{ remote.feedlabel }}:</div>
											<div class="spec-value">{{ remote.qty }}</div>
										</li>
									</ul>
								</ng-container>
								<ng-container *ngIf="!pdata.remote">
									<ul class="entry-list p-0 m-0">
										<li>
											<div class="spec-label">N/A:</div>
											<div class="spec-value">N/A</div>
										</li>
									</ul>
								</ng-container>
							</mat-card-content>

						</mat-card>
					</ng-container>
				</div>
				<app-purchase-data [item]="selecteditem" (newitemdata)="updateItem($event)"></app-purchase-data>
			</mat-tab>
		</mat-tab-group>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #purchasedetails let-modal (click)="stopProp($event)">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Purchase Information</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row" *ngFor="let item of purchase_items">
			<div class="col-12">
				<app-purchase-data [item]="item" (newitemdata)="updateItem($event)"></app-purchase-data>
			</div>
			<div [ngClass]="item.purchdata.length == 1 ? 'col-md-12': 'col-md-6'" *ngFor="let pdata of item.purchdata">
				<div class="card highlight" (click)="setPurchOrderOptions(item.cart_id, pdata)">
					<span matBadge="{{ pdata.preferred_sort }}" matBadgeSize="large" matBadgeColor="accent"></span>
					<div class="card-header text-center">
						<h4 class="card-title text-center">{{ pdata.supplierid }} - {{ pdata.suppname }} {{ pdata.price | currency }}</h4>
					</div>
					<div class="card-body text-center">
						<div class="col-12">
							<h5>{{ pdata.stockid }} {{ pdata.supplierdescription }}</h5>
						</div>
						<div class="col-12 p-0 m-0">
							<ul class="entry-list p-0 m-0" *ngFor="let remote of pdata.remote">
								<li>
									<div class="spec-label">{{ remote.feedlabel }}:</div>
									<div class="spec-value">{{ remote.qty }}</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #itemdetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title"><b>{{ item_details.stockid }}</b><span [innerHTML]="item_details.name"></span> &nbsp;&nbsp;&nbsp;
			<a *ngIf="config.env.package == 'tires' || (config.env.package == 'beauty'  && !issalesman)" [routerLink]="'/inventory/view/'+item_details.stockid"><i class="pointer fa fa-edit"></i></a>
		</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row">
			<mat-card class="tire-card " *ngIf="item_details">
				<mat-card-title-group>
					<mat-card-title>
						<h3 [innerHTML]="cleanAndFormatHTML(item_details.descriptions.name)"></h3>
						<section class="section-small" [innerHTML]="cleanAndFormatHTML(item_details.descriptions.description)"></section>
					</mat-card-title>
					<div class="tire-image">
						<div class="tire-image-container" *ngIf="item_details.image_large">
							<img mat-card-lg-image src="{{ item_details.image_large }}" class="img img-responsive large-thumb" lazy>
						</div>
					</div>
				</mat-card-title-group>
				<mat-card-content class="padding-0">
					<mat-tab-group #tabPanel mat-align-tabs="start" color="accent">
						<mat-tab label="Overview">
							<div class="row">
								<div class="col-md-6">
									Features:<br>
									<span [innerHTML]="cleanAndFormatHTML(item_details.descriptions.features)"></span>
								</div>
								<div class="col-md-6">
									Benefits:<br>
									<span [innerHTML]="cleanAndFormatHTML(item_details.descriptions.benefits)"></span>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Attributes">
							<div class="row">
								<div class="col-md-6">
									<ul class="spec-list" *ngFor="let a of item_details.additional; let i = index">
										<li *ngIf="!(i % 2)">
											<div class="spec-label"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
								<div class="col-md-6">
									<ul class="spec-list" *ngFor="let a of item_details.additional; let i = index">
										<li *ngIf="i % 2">
											<div class="spec-label ml-1"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Specs">
							<!-- <mat-form-field appearance="standard" *ngIf="!addingattribute" class="text-right">
								<mat-label>Search Attributes</mat-label>
								<input type="text" class="text-right m-0 p-0" matInput (input)="searchAttributes($event.target.value)" />
							</mat-form-field> -->

							<div class="row">
								<div class="col-6">
									<ul class="spec-list" *ngFor="let a of item_details.specs; let i = index">
										<li *ngIf="!(i % 2)">
											<div class="spec-label"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
								<div class="col-6">
									<ul class="spec-list" *ngFor="let a of item_details.specs; let i = index">
										<li *ngIf="i % 2">
											<div class="spec-label ml-1"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
							</div>
						</mat-tab>
					</mat-tab-group>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #orderdetailsModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">{{ orderdetails.deliverto }} ( {{ orderdetails.debtorno }}.{{orderdetails.branchcode}} ) </h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-12">
				<h4>INVOICE: <b>{{ orderdetails.orderno }}</b><br>
				</h4>
			</div>
		</div>

		<div class="table table-responsive">
			<table class="table table-sm">
				<thead>
					<tr>
						<th class="text-left">ITEM</th>
						<th class="text-left">DESC</th>
						<th class="text-center">QTY</th>
						<th class="text-center">PRICE</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of orderdetails.details">
						<td class="text-left"><b>{{ item.stkcode }}</b></td>
						<td>{{ item.itemdesc }}</td>
						<td class="text-center">{{ item.quantity }} </td>

						<td class="text-right">{{ item.unitprice * item.quantity | currency }} </td>
					</tr>
				</tbody>
			</table>
		</div>

	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #custnotesModal let-modal>
	<div class="modal-header">
		<div class="row col-12 d-inline">
			<div class="float-left">
				<h4>Debtor Notes</h4>
			</div>
			<div class="float-right row">
				<h4 class="modal-title" id="modal-basic-title">{{customer.brname}}&nbsp;(<b>{{customer.debtorno}}</b>) &nbsp;&nbsp;</h4>
				<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
			</div>

		</div>
	</div>
	<div class="modal-body">
		<div class="table table-responsive" *ngIf="custNotes">
			<table class="table table-sm">
				<thead>
					<tr>
						<th class="text-left col-4">Note Type</th>
						<th class="text-left col-8">Note</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let note of custNotes" class="custnote">
						<td class="text-left col-4"><b>{{ note.note_code }}</b></td>
						<td class="text-left col-8">{{ note.note }}</td>
					</tr>
				</tbody>
			</table>
		</div>

	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-container>
	<div class="row m-0">

		<div class="col-12">

			<ng-container *ngIf="itemSearchForm">
				<form  [formGroup]="itemSearchForm" autocomplete="off" (keydown.enter)="$event.preventDefault();itemSearch()" class="no-overflow ">
					<div class="row form-inputs m-0 ml-auto mr-0 " *ngIf="customerAllowedInvoice()">
						<div class="col-md-12 mt-3 no-shadow no-shadow mb-3" *ngIf="type == '21'">
						  <div class="row align-items-center p-3  ">
							<div class="col-md-3 d-flex">
							  <ng-container *ngIf="po_upload_button">
								<button mat-stroked-button color="primary" class="upload-po-btn" (click)="$event.preventDefault();open_po_upload()" (keydown.enter)="$event.preventDefault();">Upload PO</button>
							  </ng-container>
							</div>
							<div class="col-md-9 d-flex justify-content-end">
							  <mat-slide-toggle class="toggle-search-option" [formControl]="search_part_only">Item Number Search</mat-slide-toggle>
							  <mat-slide-toggle class="toggle-search-option ml-2" [formControl]="search_vendor_items">Vendor Items Only</mat-slide-toggle>
							</div>
						  </div>
						</div>

						<ng-container [ngSwitch]="config.env.package">
							<ng-container *ngSwitchCase="'beauty'">
								<!--<div class="col-md-2" *ngIf="!config.features.fitment && type == '10'">
									 <button mat-stroked-button class="mt-2" color="white" (click)="$event.preventDefault();toggleGuide()">Populate Order</button>
								</div>-->
								<div class="ml-0 mr-auto col-md-4 col-lg-2" *ngIf="type == '10' && !isdriver && !issalesman">
									<button matPrefix mat-icon-button color="white" (click)="$event.stopPropagation();toggleFilters();" *ngIf="issalesman">
										<mat-icon>filter_list</mat-icon>
									</button>
									<app-nonstock (nonstockadded)="addNonStock($event)"></app-nonstock>
								</div>

								<!-- <div class="col-1" *ngIf="type !== 21 && !issalesman ">

									<ng-container *ngIf="custNotes">
										<button #custnotebutton  mat-stroked-button matBadgePosition="after below" matBadge="{{custNotes.length}}" (click)="openCustNotes($event);"><mat-icon class="align-middle">notes</mat-icon>Notes</button>
									</ng-container>
								</div> -->

								<div class="col-2 mt-2 text-nowrap" *ngIf="type == '10' && issalesman && config.buyfive">
									<button matPrefix mat-raised-button color="white" (click)="addBuy();" *ngIf="issalesman && !shownonstock">
										BUY5
									</button>
									<!-- <ng-container *ngIf="custNotes">
										<button #custnotebutton class="ml-1" mat-raised-button matBadgePosition="after below" matBadge="{{custNotes.length}}" (click)="openCustNotes($event);"><mat-icon class="align-middle">notes</mat-icon>Notes</button>
									</ng-container> -->
									<app-nonstock (nonstockadded)="addNonStock($event)" *ngIf="issalesman && shownonstock"></app-nonstock>
								</div>
							</ng-container>

							<ng-container *ngSwitchCase="'tires'">
								<div class="col-md-2" *ngIf="config.features.fitment && type == '10' && !isdriver">
									<button mat-stroked-button color="white" (click)="$event.preventDefault();toggleGuide()" (keydown.enter)="$event.preventDefault();">Fitment Guide</button>
								</div>
								<div class="col-md-2" *ngIf="type == '10' && !isdriver">
									<app-nonstock (nonstockadded)="addNonStock($event)"></app-nonstock>
								</div>
							</ng-container>
						</ng-container>
						<div class="col-md-4 col-lg-2 mt-3" *ngIf="type == '11'" [ngClass]="{'d-none': isdriver}">
							<mat-slide-toggle [formControl]="search_customer_items">Customer Purchases Only </mat-slide-toggle>
						</div>

						<div class="col-md-12 col-lg-6" @addFilters *ngIf="issalesman">
							<app-item-filters [items]="filtered_items" [keywords]="lastsearch" [limit]="itemSearchForm.get('limit').value" [customer]="customer_data" (itemupdate)="updateFilteredItems($event)" (clearAll)="clearSearch($event)" (filtersSelected)="updateFilters($event)">
							</app-item-filters>
						</div>
						<div class="ml-auto col-md-4 col-lg-2" *ngIf="!issalesman">
							<mat-form-field appearance="standard" class="">
								<mat-label>Search Record Limit</mat-label>
								<mat-select tabindex="-1" formControlName="limit" (selectionChange)="itemSearch()">
									<mat-option *ngFor="let limit of limits" [value]="limit.value">
										{{limit.name }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-4 col-lg-2" *ngIf="!issalesman">
							<mat-form-field appearance="standard" class="">
								<mat-label>Sort</mat-label>
								<mat-select tabindex="-1" formControlName="sort" (selectionChange)="itemSearch()">
									<mat-option *ngFor="let sort of sorts" [value]="sort.value">
										{{sort.name }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>

						<div class="col-12 " [ngClass]="(config.features.fitment && config.features.nonstock) || issalesman ? 'col-lg-4' :'col-lg-4' ">
							<mat-form-field appearance="outline">
								<mat-label>Search For Items</mat-label>
								<button matPrefix mat-icon-button color="white" (click)="$event.stopPropagation();itemSearchForm.get('keywords').setValue('');" *ngIf="issalesman && itemSearchForm.get('keywords').value != ''">
									<mat-icon>clear</mat-icon>
								</button>
								<button matPrefix mat-icon-button color="white" (click)="$event.stopPropagation();toggleFilters();" *ngIf="!issalesman">
									<mat-icon>filter_list</mat-icon>
								</button>

								<ng-container *ngIf="issalesman">
									<input tabindex="1" #itemsearch matInput value="" formControlName="keywords" class="text-right" autofocus (keydown.enter)="$event.stopPropagation();$event.preventDefault();itemSearch();" appCollapseKeyboard>
								</ng-container>
								<ng-container *ngIf="!issalesman">
									<input tabindex="1" #itemsearch matInput value="" formControlName="keywords" class="text-right" autofocus (keydown.enter)="$event.stopPropagation();$event.preventDefault();itemSearch();" >
								</ng-container>
								<span matSuffix>
									<button #itemSearchButton tabindex="-1" mat-icon-button color="white" (click)="handleMouseDown($event);itemSearch();">
										<mat-icon>search</mat-icon>
									</button>
								</span>
								<mat-progress-bar mode="indeterminate" color="accent" *ngIf="searching"></mat-progress-bar>
							</mat-form-field>
						</div>

					</div>
				</form>
			</ng-container>
			<div class="col-12 text-center" *ngIf="showGuide">
				<app-tire-guide [input]="filtered_items" (output)="runFunction($event)"></app-tire-guide>
			</div>
			<div class="text-right" #resultContainer>
				RESULTS: ({{ filtered_items.length }})

				<button class="toggle-button" (click)="toggleGridView()" *ngIf="issalesman && filtered_items.length">
					<span class="icon">
						<mat-icon *ngIf="isGridView">view_list</mat-icon>
						<mat-icon *ngIf="!isGridView">view_module</mat-icon>
					</span>
				</button>
				<ng-container *ngIf="searched && !filtered_items.length && !searching">
					<div class="w-100 alert alert-danger">No results</div>
				</ng-container>

				<ng-container *ngIf="type =='10'">
					<div class="flex justify-between text-center max-w-xl mx-auto alert alert-danger" *ngIf="customer.disabletrans && !customer.dissallowinvoices && customer.dissallowinvoices !='1' && customer.disabletrans !='0'">
						<h3>CUSTOMER IS DISABLED</h3>
					</div>
					<div class="flex justify-between text-center max-w-xl mx-auto alert alert-danger flashing flash" *ngIf="customer.dissallowinvoices && customer.dissallowinvoices !='0'">
						<h2>CUSTOMER IS ON AR HOLD</h2>
					</div>
				</ng-container>

			</div>
			<ng-container>
				<div class="row m-0">
					<div class="col-md-3" @addFilters *ngIf="showfilters && !issalesman">
						<app-item-filters [items]="filtered_items" [keywords]="lastsearch" [limit]="itemSearchForm.get('limit').value" [customer]="customer_data" (itemupdate)="updateFilteredItems($event)" (filtersSelected)="updateFilters($event)">
						</app-item-filters>
					</div>
					<div @addFilters [ngClass]="(!showfilters || issalesman) ? 'col-md-12 p-0': 'col-md-9 p-0'">
						<div [ngSwitch]="config.env.package">
							<ng-container *ngSwitchCase="'beauty'">
								<ng-container *ngIf="!issalesman">
									<app-beauty-template
									[customer]="customer"
									[isdriver]="isdriver"
									[issalesman]="issalesman"
									[type]="type"
									[filtered_items]="filtered_items"
									[isGridView]="isGridView"
									(addItem)="emitaddItem($event)"
									(handleEnter)="handleEnterRec($event)"
									(addMix)="emitaddMix($event)"
									(addEduEvent)="emitaddEdu($event)"
									(updateCart)="emitUpdateCart($event)"
									*ngIf="!config.liveinventory">
									</app-beauty-template>
									<app-beauty-wqoh
									[customer]="customer"
									[isdriver]="isdriver"
									[issalesman]="issalesman"
									[type]="type"
									[filtered_items]="filtered_items"
									[isGridView]="isGridView"
									(addItem)="emitaddItem($event)"
									(handleEnter)="handleEnterRec($event)"
									(addMix)="emitaddMix($event)"
									(addEduEvent)="emitaddEdu($event)"
									(updateCart)="emitUpdateCart($event)"
									*ngIf="config.liveinventory">
									</app-beauty-wqoh>
								</ng-container>
								<ng-container *ngIf="issalesman">
									<app-salesman-template
									[customer]="customer"
									[isdriver]="isdriver"
									[issalesman]="issalesman"
									[isGridView]="isGridView"
									[type]="type"
									[filtered_items]="filtered_items"
									(addItem)="emitaddItem($event)"
									(addMix)="emitaddMix($event)"
									(addEduEvent)="emitaddEdu($event)"
									(updateCart)="emitUpdateCart($event)"
									(clearSearch)="clearSearchedFor()"
									*ngIf="!config.liveinventory">
									</app-salesman-template>
									<app-salesmanwqoh-template
									[customer]="customer"
									[isdriver]="isdriver"
									[issalesman]="issalesman"
									[isGridView]="isGridView"
									[type]="type"
									[filtered_items]="filtered_items"
									(addItem)="emitaddItem($event)"
									(addMix)="emitaddMix($event)"
									(addEduEvent)="emitaddEdu($event)"
									(updateCart)="emitUpdateCart($event)"
									*ngIf="config.liveinventory">
									</app-salesmanwqoh-template>
								</ng-container>
							</ng-container>
							<ng-container *ngSwitchCase="'tires'">
								<app-tires-template
								[customer]="customer"
								[isdriver]="isdriver"
								[issalesman]="issalesman"
								[type]="type"
								[filtered_items]="filtered_items"
								(addItem)="emitaddItem($event)"
								(addMix)="emitaddMix($event)"
								(addEduEvent)="emitaddEdu($event)"
								(updateCart)="emitUpdateCart($event)"
								></app-tires-template>
							</ng-container>
							<ng-container *ngSwitchDefault>
								<app-tires-template
								[customer]="customer"
								[isdriver]="isdriver"
								[issalesman]="issalesman"
								[isGridView]="isGridView"
								[type]="type"
								[filtered_items]="filtered_items"
								(addItem)="emitaddItem($event)"
								(addMix)="emitaddMix($event)"
								(addEduEvent)="emitaddEdu($event)"
								(updateCart)="emitUpdateCart($event)"></app-tires-template>
							</ng-container>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</ng-container>
