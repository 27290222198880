import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, EventEmitter, Pipe, PipeTransform, Renderer2, AfterViewInit, OnChanges, SimpleChanges, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate, animation } from '@angular/animations';
import { CustomerService } from '../../../../../services/customer.service';
import { OrdersService } from '../../../../../services/orders.service';
import { InventoryService } from '../../../../../services/inventory.service';
import { EducationService } from '../../../../../services/education.service';
import { GlobalSearchService } from '../../../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../../../services/globals.service';
import { UsersService } from '../../../../../services/users.service';
import { PurchasingService } from '../../../../../services/purchasing.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
	selector: 'app-beauty-template',
	templateUrl: './beauty-template.component.html',
	styleUrls: ['./beauty-template.component.scss']
})
export class BeautyTemplateComponent implements OnInit, AfterViewInit {
	@Input() customer: any = false;
	@Input() isdriver: any = false;
	@Input() type: any = '10';

	@Input() filtered_items: any = [];

	@Output() addItem = new EventEmitter < any > ();
	@Output() addMix = new EventEmitter < any > ();
	@Output() addEduEvent = new EventEmitter < any > ();
	@Output() updateCart = new EventEmitter < any > ();
	@Output() flipToReview = new EventEmitter < any > ();
	@Output() handleEnter = new EventEmitter < any > ();

	itemSearchForm: UntypedFormGroup;
	regForm: UntypedFormGroup;
	search_customer_items = new UntypedFormControl(false)
	search_vendor_items = new UntypedFormControl(false)
	filters: any = [];
	screenHeight: number;
	screenWidth: number;
	isInputFocused = false;

	limits = [
		{ name: '25', value: '25' },
		{ name: '50', value: '50' },
		{ name: '100', value: '100' },
		{ name: '200', value: '200' },
		{ name: '300', value: '300' },
		{ name: '500', value: '500' },
		{ name: 'No Limit', value: false },
	];

	sorts = [
		{ name: 'All Availability, Sell Low To High', value: 'avail.sell|ASC' },
		{ name: 'All Availability, Sell High To Low', value: 'avail.sell|DESC' },
		{ name: 'Local Availability, Sell Low To High', value: 'local.sell|ASC' },
		{ name: 'Local Availability, Sell High To Low', value: 'local.sell|DESC' },
		{ name: 'Sell Low To High', value: 'price|ASC' },
		{ name: 'Sell High To Low', value: 'price|DESC' },
		{ name: 'Name ASC', value: 'description|ASC' },
		{ name: 'Name DESC', value: 'description|DESC' },
		{ name: 'SKU ASC', value: 'stockid|ASC' },
		{ name: 'SKU DESC', value: 'stockid|DESC' },
	];

	showGuide = false;
	showfilters = false;
	searching = false;
	addingitems = false;
	inventorysearching: any = false;

	purchase_items: any = [];
	item_details: any = [];
	previous_search = '';
	salesman: any = false;
	//modal
	selecteditem: any = false
	itemhistory: any = false;
	editing_order: any = false;
	user: any = [];
	expanded_rows: any = [];
	promo: any = false;
	showorder = false;
	orderdetails: any = false;
	searched: any = false;
	po_upload_button: any = false;

	editsallowed: any = {
		discounts: true,
		editar: true,
		editbilling: true,
		editshipping: true,
		editcontact: true,
		editprofile: true,
		editnotes: true,
		takepayment: true,
	}

	@ViewChild('purchasedetails') purchasedetails: ElementRef;
	@ViewChild('orderdetailsModal') orderdetailsModal: ElementRef;
	@ViewChild('itemHistoryRef') itemHistoryRef: ElementRef;
	@ViewChild('configproductele') configproductele: ElementRef;
	@ViewChild('nonstock') nonstockref: ElementRef;
	@ViewChild('mmmodal') mmodalref: ElementRef;
	@ViewChild('edumodal') eventmodalref: ElementRef;
	@ViewChild('serialmodal') serialmodalref: ElementRef;
	@ViewChild('uploadpo') uploadporef: ElementRef;
	@ViewChild('itemdetails') itemDetails: ElementRef;
	@ViewChild('itemsearch') itemsearchRef: ElementRef;
	@ViewChild('resultContainer') resultContainerRef: ElementRef;
	@ViewChild('firstItem') firstItem: ElementRef;
	@ViewChildren('inputElement') inputElements: QueryList < ElementRef > ;

	files: any = [];
	inputdata: any = false;


	poUploadColumns: string[] = ['stockid', 'description', 'manufacturer', 'line', 'vendor', 'min', 'max', 'qty', 'price'];
	uploaddata: any = false;
	processing_recs = false;
	poDataSource: any = false;
	poReviewReady = false;
	vendors_detected: any = false;
	uploadPodata: any = false;
	from_reorder = false;
	poHelp = false;

	upload_error: any = false;
	issalesman = false;
	config: any = [];
	event: any = [];
	serialqtyneeded: any = 0;
	serialqtypicked: any = 0;

	constructor(private educationService: EducationService, private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal, public usersService: UsersService, private renderer: Renderer2, private purchasingservice: PurchasingService) {

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			this.user = this.globalSearchService.user;

			this.globalSearchService.user.subscribe(u => {

				if (u.user.issalesman) {
					this.issalesman = true;
					this.showfilters = true;
					this.editsallowed = r.salesmanAllowed
				}
			})
		});

		this.getScreenSize();
	}
	@HostListener('window:resize', ['$event'])

	ngOnInit(): void {

		if (this.type == '11') {
			this.search_customer_items.setValue(true);
		}

		if (this.type == '21') {
			this.search_vendor_items.setValue(false);
			this.po_upload_button = true;
		}

		if (this.user.value.user.salesman != '') {
			this.salesman = this.user.value.user.salesman;
		}

		switch (this.config.env.package) {
			case 'beauty':
				this.itemSearchForm = this.fb.group({
					keywords: ['', Validators.required],
					limit: [this.limits[3].value, Validators.required],
					sort: [this.sorts[8].value, Validators.required],
				});

				break;
			default:
				this.itemSearchForm = this.fb.group({
					keywords: ['', Validators.required],
					limit: [this.limits[2].value, Validators.required],
					sort: [this.sorts[2].value, Validators.required],
				});

				if (this.isdriver) {
					this.itemSearchForm = this.fb.group({
						keywords: ['', Validators.required],
						limit: [this.limits[0].value, Validators.required],
						sort: [this.sorts[6].value, Validators.required],
					});
				}
				break;
		}
	}

	customerAllowedInvoice() {
		let allowed = true;
		if (this.customer.dissallowinvoices && this.customer.dissallowinvoices != '0' && this.config.arhold == true) {
			allowed = false;
		}
		return allowed;
	}

	getScreenSize(event ? ) {
		this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;
	}

	showPurchases(stockidid: any) {

	}

	runFunction(newvalue: any) {
		this.itemSearchForm.get('keywords').setValue(newvalue);
		this.itemSearch();
	}

	addNonStock(event: any) {
		const item = event;
		const data = { item: item, debtorno: this.customer.debtorno, branchcode: this.customer.branchcode }


		this.orderService.addNonStock(data).subscribe((results: any) => {
			this.updateCart.emit(results);
			this.globalSearchService.showNotification(item.description + ' x ' + item.qty + ' Added', 'success', 'bottom', 'right');
		});
	}

	triggerMm(item: any) {
		const data = {
			stockid: item.stockid,
			qty: item.quantity,
		}

		this.inventoryService.getPromo(data).subscribe(r => {
			this.promo = r;
			this.promo.intros.map((i)=>{
				i.viewing = true
				i.forceopen = false;
			})
			this.promo.itemdesc = item;
			this.promo.qty = r.qty
			this.modalService.open(this.mmodalref, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result: any) => {

			}, (reason) => {
				this.promo = false;
				this.selecteditem = false;
			});
		})
	}

	triggerEdu(item: any){
		const data = {
			stockid: item.stockid,
		}

		this.educationService.getEventByStockId(data).subscribe(r => {
			this.event = r;
			this.regForm = this.fb.group({
					eduname: ['', Validators.required],
					educell: ['', Validators.required],
					eduemail:['', Validators.required],
					eduinsta: [''],
				});
			this.modalService.open(this.eventmodalref, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false, keyboard: false, backdrop: 'static' }).result.then((result: any) => {

			}, (reason) => {
				this.event = false;
			});
		})
	}
	triggerSerial(item: any){
	
		this.selecteditem=item;
		this.serialqtyneeded = item.quantity;
		this.modalService.open(this.serialmodalref, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false, keyboard: false, backdrop: 'static' }).result.then((result: any) => {

			}, (reason) => {
				this.event = false;
			});
	}
	addSeat(){


	const s = {name: this.regForm.get('eduname').value, cell: this.regForm.get('educell').value, email: this.regForm.get('eduemail').value, insta: this.regForm.get('eduinsta').value};
	if(this.regForm.valid){
	this.event.seats.push(s);
	this.regForm = this.fb.group({
					eduname: ['', Validators.required],
					educell: ['', Validators.required],
					eduemail:['', Validators.required],
					eduinsta: [''],
				});
	}
	const seatcount = this.event.seats.length;
	this.event.total = this.financial(seatcount * parseFloat(this.event.discount));

	}

	remSeat(seat: any){
	const index = this.event.seats.indexOf(seat);
		if (index >= 0) {
			this.event.seats.splice(index, 1);
		}
		const seatcount = this.event.seats.length;
		this.event.total = this.financial(seatcount * parseFloat(this.event.discount));
	}
	addedureg() {
		//test kit first
		this.addEduEvent.emit(this.event);
		this.modalService.dismissAll();
	}
	itemSearch() {

		const keywords = this.itemSearchForm.get('keywords').value;

		let proceed = false;

		if (!this.filters.length && keywords == '') {
			proceed = false;
		} else {
			if (this.filters.length || keywords != '') {
				proceed = true;
			}
		}

		if (proceed) {

			this.globalSearchService.hideSideBar();
			this.searching = true;
			this.addingitems = true;

			this.filtered_items = [];

			if (this.inventorysearching) {
				this.inventorysearching.unsubscribe()
			}

			const data = {
				keywords: keywords,
				limit: this.itemSearchForm.get('limit').value,
				sort: this.itemSearchForm.get('sort').value,
				customer: this.customer,
				filters: this.filters,
				customer_items_only: this.search_customer_items.value,
				vendor_items_only: this.search_vendor_items.value,
				type: this.type,
				user: this.user._value.user
			};


			this.inventorysearching = this.orderService.getItemSearch(data).subscribe((items: any) => {
				this.filtered_items = items
				this.searched = true;
				this.searching = false;
				if (items.length) {
					if (!this.showfilters) {
						// this.itemSearchForm.get('keywords').setValue('');
					}
				}
				if (this.itemsearchRef) {
					this.globalSearchService.blurInputElement(this.itemsearchRef);
				}
			});
		} else {
			this.searching = false;
		}
	}

	expand_row(index) {
		this.expanded_rows.includes(index) ? this.expanded_rows.splice(this.expanded_rows.indexOf(index), 1) : this.expanded_rows.push(index);

	}

	isExpanded(index) {
		return this.expanded_rows.includes(index);
	}


	getLineTotal(item: any): number {
		let qty = item.quantity
		if (!qty || item.quantity == 0) {
			qty = 1;
		}

		if (this.config.env.package == 'beauty') {
			var value = this.financial(qty) * (this.financial(item.price) - this.financial(this.getItemDiscountMultiplier(item) * item.price))
		} else {
			//removed discount - handled on php side in $this->shop->getAlternateCustPrice
			var value = (this.financial(qty) * (this.financial(item.price)) + this.financial(item.fetvalue))
		}

		return value;

	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	updateFilters(event: any) {
		this.filters = event;
		this.itemSearch();
	}

	updateFilteredItems(event: any) {
		this.searching = true;
	}

	updateItem(data: any) {
		const itemdata = this.filtered_items.filter((item: any) => {
			return data.item.stockid == item.stockid
		})[0]

		const index = this.filtered_items.indexOf(itemdata);
		this.filtered_items[index] = itemdata;
	}

	addPromo(item: any) {
		const data = {
			stockid: item.stockid,
			qty: item.quantity,
		}

		this.inventoryService.getPromo(data).subscribe(r => {
			this.promo = r;
			this.promo.itemdesc = item;
			this.promo.qty = r.qty
			this.addMM(this.promo);
		})
	}

	// getNumberOfNonType1Intros(intros): number {
	// 	let count = 0;
	// 	for (const intro of intros) {
	// 		if (intro.type !== '1' && intro.type !== 1) {
	// 			count++;
	// 		}
	// 	}
	// 	return count > 0 ? count : 1;
	// }

	getNumberOfNonType1Intros(intros: any[]): number {
		const count = intros.filter(intro => intro.type !== '1' && intro.type !== 1).length;
		return count || 1;
	}

	isCompleted(promo: any): boolean {
		var disablebutton = false;
		(promo.intros).forEach(intro => {
			if(intro.mmid){
				if(!disablebutton){
					disablebutton = this.updateMMQty(intro) > 0 ? true : false;
				}
			}
		});
		return disablebutton;
	}

	getColumnSize(intros: any[]): number {
	  const nonType1Count = this.getNumberOfNonType1Intros(intros);
	  return Math.min(12 / nonType1Count, 3); // Limit to col-3 if only one item
	}


	addMM(intro: any) {
		//test kit first
		this.addMix.emit(intro);
		this.modalService.dismissAll();
	}

	setParent(event: any, parent: any) {
		parent.qty = event.target.value;
		//multiply base values by current
		parent.intros.forEach((intro: any) => {
			intro.floor = intro.original_floor
			intro.floor = intro.floor * parent.qty;
			this.updateMMQty(intro);
		})

	}

	updateMMQty(mm: any) {
		mm.error = true;
		const items = mm.items || [];

		var child = false;
		if(mm['dsptype'] == '$'){
			var used = items.reduce((accumulator, item) => accumulator + (parseFloat(item.quantity)* parseFloat(item.price)) , 0);
			child = true;
		}else{
			var used = items.reduce((accumulator, item) => accumulator + parseFloat(item.quantity), 0);
		}

		const floorValue = this.financial(mm.floor);
		let remainingValue = floorValue - this.financial(used);

		if(!child){
			if (remainingValue < 0) {
				mm.error = true;
				let overage = -remainingValue; // Make the overage positive

				for (let i = items.length - 1; i >= 0; i--) {
					const item = items[i];
					const itemQuantity = parseFloat(item.quantity);

					if (itemQuantity > 0) {
						if (itemQuantity >= overage) {
							item.quantity = Math.max(0, Math.floor((itemQuantity - overage) * 100) / 100); // Ensure two decimal places
							overage = 0;
							break;
						} else {
							item.quantity = 0;
							overage -= itemQuantity;
						}
					}
				}

				this.globalSearchService.showNotification('Error, Total Qty Adjusted: Items Quantities Reduced', 'danger', 'bottom', 'right');
			} else {
				mm.error = remainingValue !== 0;
			}
		}

		var retValue;
		if(!child){
			retValue = Math.max(0, remainingValue);
		}else{
			retValue = remainingValue;
		}

		if(retValue == 0){
			if(!mm.forceopen){
				mm.viewing = false;
			}
		}else{
			mm.forceopen = false;
		}

		return retValue;
	}

	decrementmm(item: any, intro: any) {
		if (item.quantity > 0) {
			item.quantity -= 1;
			this.updateMMQty(intro);
		}
	}

	incrementmm(item: any, intro: any) {
		if (this.updateMMQty(intro) > 0 || intro['dsptype'] == '$') {
			item.quantity += 1;
			this.updateMMQty(intro);
		} else {
			this.globalSearchService.showNotification('Promo Requirements Filled', 'danger', 'bottom', 'right')
		}
	}

	qtyMM(event: any, item: any, intro: any) {
		item.quantity = 0;
		const original_qty = item.quantity;
		let qty_used = this.financial(event.target.value);
		const test_qty = parseFloat(original_qty) + parseFloat(event.target.value);
		const maxqty = this.updateMMQty(intro);

		if (test_qty > maxqty) {
			qty_used = maxqty;
			this.globalSearchService.showNotification('Maximum of ' + qty_used + ' Allowed', 'danger', 'bottom', 'right')
		}

		if(isNaN(qty_used)){
			event.target.value = 0;
			qty_used = 0;
		}else{
			event.target.value = qty_used;
		}

		item.quantity = qty_used
		this.updateMMQty(intro);
	}

	decrement(item: any) {
		const index = this.filtered_items.indexOf(item);
		const quantity = Number(this.filtered_items[index].quantity);

		if (!isNaN(quantity)) {
			if (this.config.env.package == 'tires') {
				if (quantity > 0) {
					this.filtered_items[index].quantity = quantity - 1;
				}
			} else { // allowing negatives
				this.filtered_items[index].quantity = quantity - 1;
			}
		}
	}

	increment(item: any) {
		const index = this.filtered_items.indexOf(item);
		const quantity = Number(this.filtered_items[index].quantity);

		if (!isNaN(quantity)) {
			this.filtered_items[index].quantity = quantity + 1;
		}
	}

	updateSearchPrice(event: any, item: any) {
		const index = this.filtered_items.indexOf(item);
		if (!isNaN(this.financial(item.price))) {
			this.filtered_items[index].price = this.financial(event.target.value);
		}
	}

	updateSearchQuantity(event: any, item: any) {
		const index = this.filtered_items.indexOf(item);
		this.filtered_items[index].quantity = event.target.value;
	}

	getItemDiscountMultiplier(item) {
		if (!isNaN(this.financial(item.discount))) {
			return this.financial(item.discount / 100);
		}
		return 0;
	}

	updateSearchDiscount(event: any, item: any) {
		const index = this.filtered_items.indexOf(item);

		if (!isNaN(this.financial(event.target.value))) {
			this.filtered_items[index].discount = this.financial(event.target.value);
			if (this.filtered_items[index].discount != 0) {
				this.filtered_items[index].hasdiscount = true;
			} else {
				this.filtered_items[index].hasdiscount = false;
			}
		}
	}


	addToOrder(item: any) {
		const copyOfItems = Object.assign([], this.filtered_items);
		let this_index = '';

		this_index = this.filtered_items.indexOf(item)
		if (!copyOfItems[this_index].quantity) {
			copyOfItems[this_index].quantity = 1;
		}


		const allow = true;

		if (this.isdriver) {
			if (copyOfItems[this_index].quantity > copyOfItems[this_index].max_credit) {
				alert('Maximum Qty Credit ' + copyOfItems[this_index].max_credit + ' for ' + copyOfItems[this_index].stockid + ' Reached, call main office.');
				copyOfItems[this_index].quantity = 0;
				return false;
			}
		}

		const add_items = [];

		copyOfItems.forEach((items: any, index) => {
			items.allownegative = true;
			items.isnegative = false;

			if (this.type == '21') {

				const qty_request = parseFloat(items.quantity);
				if (qty_request != 0) {
					const qty_min = parseFloat(items.minorderqty);
					const qty_max = parseFloat(items.maxorderqty);

					const qty_max_stock = parseFloat(item.minmax.maximum);
					const qty_min_stock = parseFloat(item.minmax.minimum);

					if (qty_min > 0 && qty_max > 0) {
						if (qty_request > qty_max) {
							// allow = confirm('Attempting to Purchase more than '+qty_max+' for '+items.description+', procced?');
						}

						if (qty_request < qty_min) {
							// allow = confirm('Attempting to Purchase less than '+qty_min+' for '+items.description+', procced?');
						}
					}

					if (allow) {
						if (qty_max_stock > 0 && qty_max_stock > 0) {
							if (qty_request > qty_max_stock) {
								// allow = confirm('Attempting to Purchase more than '+qty_max_stock+' for '+items.description+', procced?');
							}

							if (qty_request < qty_min_stock) {
								// allow = confirm('Attempting to Purchase less than '+qty_min_stock+' for '+items.description+', procced?');
							}
						}
					}
				}
			}
			if (allow) {
				if (parseFloat(items.quantity) != 0) {
					add_items.push(items);
				}
			}

		});

		this.addItem.emit(add_items);
		this.filtered_items.forEach(i => { i.quantity = 0; });

	}

	addMultipleToOrder() {
		this.filtered_items.forEach(element => {
			element.quantity > 0 ? this.addToOrder(element) : '';
		});
	}

	setPurchOrderOptions(cart_id: any, selected: any) {
		const data = { cart_id: cart_id, options: selected }
		this.orderService.setPoOptions(data).subscribe((results: any) => {
			this.modalService.dismissAll();
			this.purchase_items = [];
		});

		this.updateCart.emit(true);
	}


	//modals
	viewitemOrderHistory(item: any) {
		this.selecteditem = item;
		this.customerService.getCustomerHistoryItem(this.customer.debtorno, item.stockid).subscribe((result: any) => {
			this.itemhistory = result
			this.inventoryService.getPurchaseHistory(item.stockid).subscribe(async (result: any) => {
				this.itemhistory.purchase = result;
			})

			this.modalService.open(this.itemHistoryRef, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result: any) => {

			}, (reason) => {
				this.itemhistory = [];
				this.selecteditem = false;
			});
		});
	}

	viewPurchOrderOptions(line: any) {
		line.allownegative = true;
		const data = { cart_id: line.cart_id, stockid: line.stockid }
		this.orderService.getPurchDataFromLine(data).subscribe((results: any) => {
			this.purchase_items.push(results);
			this.modalService.open(this.purchasedetails, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result: any) => {

			}, (reason) => {
				this.purchase_items = [];
			});
		});
	}

	loadOrder(order) {
		this.orderService.getOrderInqSearch(order).subscribe((results: any) => {
			this.showorder = true;
			this.orderdetails = results[0];


			this.modalService.open(this.orderdetailsModal, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {

			}, (reason) => {

			});

		});
	}

	openModal(content, item) {

		this.item_details = item;
		this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {
			this.item_details = false;
		});
	}

	toggleFilters() {
		this.showfilters = (this.showfilters) ? false : true;

		if (!this.showfilters) {
			this.filters = [];
			this.itemSearch();
		}

	}

	toggleGuide() {
		this.showGuide = (this.showGuide) ? false : true;
	}

	open_po_upload() {
		this.modalService.open(this.uploadporef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {
			this.uploadPodata = [];
			this.files = [];
			this.resetUploadPo()
			this.upload_error = false;
		});
	}

	uploadPo() {
		this.uploadPodata = {
			items: [],
			lines: { good: 0, bad: 0 },
			units: { good: 0, bad: 0 }
		};

		this.upload_error = false;

		const vendors: Array < String > = [];
		let files_to_proccess = this.files.length;

		for (let i = 0; i < this.files.length; i++) {
			const name = this.files[i].name;
			const type = this.files[i].type;

			this.readFile(this.files[i]).then(fileContents => {
				const upload = {
					content: fileContents,
					name: name,
					mime: type,
				}
				this.orderService.uploadPo(upload).subscribe((results: any) => {
					if (results['error']) {
						(!this.upload_error) ? this.upload_error = []: '';
						this.upload_error = [results['error'], ...this.upload_error];
					} else {
						this.uploaddata = results.rows;
						this.processing_recs = true;
						this.uploaddata.map((row) => {
							if (parseFloat(row.qty_purchase) != 0) {
								vendors.push(row.vendor);
								this.uploadPodata['items'].push({ stockid: row.stockid, quantity: row.qty_purchase, vendor: row.vendor, price: row.price, description: row.description, discount: 0 })
							}
						});
					}

					if (--files_to_proccess == 0) {
						if (this.upload_error.length) {
							this.resetUploadPo();
							return;
						}
						//validate items uploaded are real
						const data = { items: this.uploadPodata['items'] }
						this.purchasingservice.buildPoFromUpload(data).subscribe((res) => {
							this.poDataSource = {};

							if (res['good']) {
								this.poDataSource['good'] = new MatTableDataSource(res['good'].sort((a, b) => a.quantity.localeCompare(b.quantity)).reverse());
								res['good'].map((i) => {
									this.uploadPodata['lines']['good']++;
									this.uploadPodata['units']['good'] += Number(i.quantity);
								});
								this.uploadPodata['items'] = res['good'];
							}

							if (res['bad']) {
								this.poDataSource['bad'] = new MatTableDataSource(res['bad'].sort((a, b) => a.quantity.localeCompare(b.quantity)).reverse());
								res['bad'].map((i) => {
									this.uploadPodata['lines']['bad']++;
									this.uploadPodata['units']['bad'] += Number(i.quantity);
								})
							}

							this.vendors_detected = Array.from(new Set(vendors.filter((i) => { return i !== undefined }))).join(',');

							if (this.vendors_detected != '') {
								this.from_reorder = true;
							} else {
								this.from_reorder = false;
							}
							this.poReviewReady = true;
							this.processing_recs = false;
						})
					}
				});
			})
		}
	}

	buildPO() {
		if (this.vendors_detected != this.customer.supplierid && this.from_reorder) {
			if (confirm('Vendor selected does not match vendors from upload, continue?')) {
				this.addItem.emit(this.uploadPodata['items'])
				this.files = [];
				this.resetUploadPo();
				this.modalService.dismissAll();
				this.flipToReview.emit();
			}
		} else {
			this.addItem.emit(this.uploadPodata['items']);
			this.files = [];
			this.resetUploadPo();
			this.modalService.dismissAll();
			this.flipToReview.emit();
		}
	}

	resetUploadPo() {
		this.poReviewReady = false;
		this.processing_recs = false;
		this.vendors_detected = false;
		this.poDataSource = false;
	}

	toggleUploadHelp() {
		this.poHelp = this.poHelp == true ? false : true;
	}

	onSelect(event) {
		this.files.push(...event.addedFiles);
	}

	onRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
		this.upload_error = false;
	}


	private async readFile(file: File): Promise < string | ArrayBuffer > {
		return new Promise < string | ArrayBuffer > ((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = e => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = e => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				return reject(null);
			}
			reader.readAsDataURL(file);
		});

		this.files = [];
	}

	handleMouseDown(event: MouseEvent) {
		// Blur the input field to remove focus
		event.stopPropagation();
		this.itemsearchRef.nativeElement.blur();
	}

	cleanAndFormatHTML(htmlContent: string): string {
		// Clean up the HTML content
		const cleanedHTML = htmlContent
			// Remove excessive white spaces
			.replace(/\s{2,}/g, ' ')
			// Remove extra line breaks
			.replace(/\n\s*\n/g, '\n')
			// Trim leading and trailing white spaces
			.trim();

		// Format the HTML content nicely
		let formattedHTML = '';
		const parser = new DOMParser();
		const doc = parser.parseFromString(cleanedHTML, 'text/html');
		if (doc.body) {
			formattedHTML = doc.body.innerHTML;
		}

		return formattedHTML;
	}

	filterItems(intro) {
		if (intro.searchQuery) {
			intro.filteredItems = this.globalSearchService.filterItem(intro.items, intro.searchQuery, 'stockid,description,barcode,lineid')
		} else {
			intro.filteredItems = [...intro.items];
		}
	}

	onInputFocus() {
		this.isInputFocused = true;
	}

	onInputBlur() {
		this.isInputFocused = false;
	}

	trackByStockId(index: number, item: any): string {
		return item.stockid;
	}

	ngAfterViewInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.filtered_items && changes.filtered_items.currentValue.length) {
			this.focusFirstItem();
		}
	};

	handleTabKey(event: KeyboardEvent, index: number) {
		const inputsArray = this.inputElements.toArray();
		if (event.key === 'Tab' && !event.shiftKey) {
			if (index === inputsArray.length - 1) {
				event.preventDefault();
				this.firstItem.nativeElement.focus();
			}
		}
	}

	handleEnterKey(event: KeyboardEvent, index: number) {
		if (event.key === 'Enter') {
			event.preventDefault();
			this.handleEnter.emit(event);
		}
	}

	private focusFirstItem() {
		setTimeout(() => {
			if (this.firstItem) {
				const firstInput = this.firstItem.nativeElement;
				firstInput.focus();
				this.selectText(firstInput);
			}
		});
	}

	selectText(event: FocusEvent) {
		const input = event.target as HTMLInputElement;
		if(input) {
			setTimeout(() => {
				input.select();
			}, 0);
		}
	}
}
