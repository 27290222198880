<div class="row sig-container ml-0 mr-0">    
    <div class="text-right col-md-12 h-20">
        <small class="italics mr-4 font-weight-lighter">Electronic Signature</small>
    </div>
    <div class="col-md-12">
        <canvas class="w-100 canvas" #signaturePad (mousedown)="drawmode(true); setPosition($event)" (mousemove)="draw($event)" (mouseup)="drawmode(false)" (pointerdown)="drawmode(true); setPosition($event)" (pointermove)="draw($event)" (pointerup)="drawmode(false)"></canvas>
        <div class="col-md-12 ml-0 mr-0 mb-1 p-0 row">
            <div class="m-0 p-0 col-md-6 text-left">
                <button class="w-100" mat-stroked-button color="accent" (click)="clear()">Clear</button>
            </div>
            <div class="m-0 p-0 col-md-6 text-right">
                <button class="w-100" mat-stroked-button color="primary" (click)="captureCanvas()">Accept</button>
            </div>
        </div>
    </div>
</div>
