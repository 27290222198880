<ng-template #podmodal let-modal>
	<div class="modal-header ">
		<h3>Proof of Delivery</h3>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body z-top">
		<app-electric-signature></app-electric-signature>
	</div>
	<div class="modal-footer">

	</div>
</ng-template>

<ng-template #emailrma let-modal>
	<div class="modal-header ">
		<h3>RMA REVIEW</h3>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')" class="hide" id="RmaExitModal"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row align-items-center">
			<div class="col-8">
				<h4 class="customer-title">Customer: <span [innerHTML]="selectedorder.address"></span></h4>
				<mat-divider></mat-divider>
				<h5 class="item-info mt-2">Items: <span class="item-count">{{ total_cart_qty }}</span></h5>
			</div>
			<div class="col-4 text-right">
				<button mat-raised-button color="primary" class="toggle-review-btn" (click)="review = !review">
					<span>{{ review ? 'Back' : 'RMA Details' }}</span>
					<mat-icon class="ml-1" [ngClass]="{ viewing: review }">visibility</mat-icon>
				</button>
			</div>
		</div>

		<div class="row">
			<div class="col-12 text-left">
				<mat-divider></mat-divider>
				<ng-container *ngIf="!review">
					<h4 class="mt-2">Email RMA To:</h4>
					<div [ngClass]="addNewContact ? 'col-md-6' : 'col-md-10'">
						<ul>
							<ng-container *ngFor="let contact of customer.contacts">
								<ng-container *ngIf="contact.role.includes('CREDIT/RMA')">
									<li class="font-weight-lighter ">{{contact.name}}: {{contact.email}}</li>
								</ng-container>
							</ng-container>
						</ul>
					</div>
					<div [ngClass]="addNewContact ? 'col-md-6' : 'col-md-2'" class="m-auto " class="newcontact-trans">
						<button *ngIf="!addNewContact" mat-stroked-button (click)="addNewContact ? addNewContact = false : addNewContact = true">Update Contacts</button>
						<ng-container *ngIf="addNewContact">
							<h4>Update Existing Contact</h4>
							<mat-form-field appearance="outline" class="ml-2 mr-2">
								<mat-label>Contact:</mat-label>
								<mat-select [formControl]="rmaEmail">
									<mat-option [value]="">-</mat-option>
									<ng-container *ngFor="let contact of customer.contacts">
										<mat-option *ngIf="!contact.role.includes('CREDIT/RMA')" [value]="contact.email">
											<span class="optionstyling">{{contact.email}}</span>
											<span class="optionstyling"> {{(contact.role).toString()}}</span>
										</mat-option>
									</ng-container>
								</mat-select>
							</mat-form-field>
							<hr>
							<h4>New Credit/RMA Contact</h4>
							<mat-form-field class="example-full-width">
								<mat-label>Email</mat-label>
								<input matInput placeholder="Customer Email" value="" [formControl]="rmaEmailAdd">
							</mat-form-field>
							<mat-form-field class="example-full-width">
								<mat-label>Name</mat-label>
								<input matInput placeholder="Customer Name" value="" [formControl]="rmaNameAdd">
							</mat-form-field>
						</ng-container>
					</div>
				</ng-container>
				<ng-container *ngIf="review">
					<div class="pb-5 pt-2 w-100 ">
						<table mat-table [dataSource]="reviewData" class="w-100 ">
							<ng-container matColumnDef="stockid">
								<th mat-header-cell *matHeaderCellDef> Stock ID </th>
								<td mat-cell *matCellDef="let element"> {{element.stockid}} </td>
							</ng-container>
							<ng-container matColumnDef="EXT">
								<th mat-header-cell *matHeaderCellDef> Ext. </th>
								<td mat-cell *matCellDef="let element"> {{element.price * element.quantity | number: '1.2-2'}} </td>
							</ng-container>
							<ng-container matColumnDef="qty">
								<th mat-header-cell *matHeaderCellDef> QTY </th>
								<td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
							</ng-container>
							<ng-container matColumnDef="price">
								<th mat-header-cell *matHeaderCellDef> Price </th>
								<td mat-cell *matCellDef="let element"> {{element.price}} </td>
							</ng-container>
							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
						</table>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<div class="row justify-content-around m-auto w-90" *ngIf="!addNewContact">
			<button mat-flat-button [disabled]="review" color="warn" (click)="closeModalById('RmaExitModal', false)">Cancel</button>
			&nbsp;&nbsp;&nbsp;
			<button mat-flat-button [disabled]="review" color="primary" (click)="closeModalById('RmaExitModal', true)">Finalize RMA</button>
		</div>
		<div class="row justify-content-around " *ngIf="addNewContact">
			<button mat-stroked-button [disabled]="review" (click)="addNewContact = false">Back</button>
			&nbsp;
			<button mat-flat-button [disabled]="review" color="accent" (click)="updateDebtorContacts()">Update Contacts</button>
		</div>
	</div>
</ng-template>

<ng-template #orderdetails id="orderdetails" let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Order Details {{ selectedorder.invoice }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">

			<div class="col-12 text-left">
				<button mat-raised-button color="accent" (click)="toggleCredit()" class="mb-2">
					<span *ngIf="credititing">CANCEL</span>
					<span *ngIf="!credititing">RMA</span>
				</button>
				<ng-container *ngIf="credititing">
					<app-item-search [customer]="customer" [isdriver]="true" (addItem)="addItems($event)" (updateCart)="updateCart()" [type]="11" class="w-100 no-overflow"></app-item-search>


					<div class="fixed-plugin">
						<div class="dropdown show-dropdown text-center cart-fixed" *ngIf="cart_items">
							<a href="#" data-toggle="dropdown" aria-expanded="false">
								<!-- <mat-icon>shopping_cart</mat-icon> 2x?-->
								<i class="ml-auto mr-auto fa fa-shopping-cart fa-1x text-white" [ngClass]="!proceed ? 'text-danger' : ''">
									({{ cart_items.length }})
								</i>
								<span class="text-white" *ngIf="total_cart_qty"><br><i class="fa fa-caret-left fa-1x text-white" aria-hidden="true"></i> ({{total_cart_qty}})</span>
								<!-- <span class="text-white" *ngIf="!isdriver"><br> {{ getTotal() | currency}}</span> -->
								<!-- <button class="nav-item ml-auto mr-auto" mat-raised-button (click)="placeOrder()" color="accent" *ngIf="flip =='active'">
									<span *ngIf="!editing_order">Create</span>
									<span *ngIf="editing_order">Update</span>
								 </button> -->
							</a>
							<ul class="dropdown-menu cart-fixed-content" x-placement="bottom-start" id="rightcart" *ngIf="cart_items">
								<li class="header-title" [ngClass]="{'hasitems': cart_items.length }">Current RMA
									<ng-container *ngIf="cart_items.length">
										<nav class="navbar navbar-expand navbar-light bg-light no-shadow nobox">

											<button class="nav-item ml-auto mr-auto " mat-raised-button (click)="placeOrder();" color="primary">
												<span>CREATE RMA</span>
											</button>
											<!-- <button class="nav-item ml-auto mr-auto " mat-raised-button (click)="placeOrder('pickup');" color="accent"> -->
											<button class="nav-item ml-auto mr-auto " mat-raised-button (click)="placeOrder('pickup')" color="accent">
												<span>CREATE & PICKUP RMA</span>
											</button>
										</nav>
									</ng-container>
								</li>
								<ng-container>
									<div class="container text-right" id="rightcart-content" *ngIf="!cart_items.length">
										<div class="row mt-0 mb-0 pt-0 pb-0 border-top small-font">
											<div class="col-md-12 mt-0 pt-0 text-center mb-3 mt-3">
												<h4>No Current Items</h4>
											</div>
										</div>
									</div>
									<div class="container text-right" id="rightcart-content">

										<ng-container *ngIf="cart_items.length" (click)="stopProp($event)">

											<div class="row mt-0 mb-0 pt-0 pb-0 border-top small-font" *ngFor="let item of cart_items" (click)="stopProp($event)" [ngClass]="{'parent': ((item.option != '') && (item.option != 'Component')) ? 'true' : '', 'component': ((item.option != '') && (item.option == 'Component')) ? 'true' : ''}">
												<div class="col-12 col-md-12 mt-0 pt-0 text-left">

													<b>{{item.stockid}}</b>&nbsp;&nbsp;<span class="bold">{{item.description}}<span *ngIf="item.option && item.option != 'Component'"><span class="parent-tag"> KIT </span></span></span>

												</div>
												<!--
												<div class="col-md-1 mt-1 p-0">
													<img src="{{ item.thumb }}" class="img img-responzive" lazy *ngIf="item.thumb" />
												</div>

												[ngClass]="{'col-md-5':!config.discounts,'col-md-3':config.discounts, 'col-md-5':item.mbflag == 'F'}"

												-->


												<div class="text-center col-md-3">
													<div *ngIf="item.option && item.option == 'Component'">
														<div class="flex-row">
															<mat-icon class="h-19">segment</mat-icon><span class="component-tag">component</span>
														</div>
													</div>
												</div>

												<div class="col-12 col-md-2 text-right" *ngIf="config.discounts && item.mbflag != 'F'">
													<mat-form-field appearance="outline" class="text-right">
														<mat-label>Discount</mat-label>
														<input matInput type="text" class="text-right" placeholder="Discount" [value]="item.discount" (input)="updateDiscount($event,item)">
														<span matSuffix>%</span>
													</mat-form-field>
												</div>
												<div class="col-6 col-md-4 m-0 p-0 ">
													<!-- <mat-form-field appearance="outline" class="m-0 p-0 text-right " [ngClass]="isdriver ? 'hide': ''" >
														<mat-label>Price</mat-label>
														<span matPrefix>$</span>
														<input matInput type="text" class="text-right" placeholder="Price" [value]="item.price" (input)="updatePrice($event,item)">
														<mat-hint *ngIf="config.discounts && item.discount > 0" class="text-right text-danger bolder">
															Discount Price {{ getDiscounPrice(item) | currency }} @ {{ item.discount }}%
														</mat-hint>
													</mat-form-field> -->
												</div>
												<div class="col-4 col-md-2 mt-0 mb-0 pt-0 pb-0 text-right">
													<mat-form-field appearance="outline" class="m-0 p-0 text-center">
														<mat-label>Qty</mat-label>
														<span matPrefix>#</span>
														<input matInput type="text" placeholder="QTY" class="text-center" [value]="item.quantity" (input)="updateQuantity($event, item)">
													</mat-form-field>
												</div>
												<div class="col-2 m-0 p-0 pt-2 text-right">
													<button mat-icon-button color="warn" aria-label="remove" (click)="removeFromOrder(item.cart_id)">
														<mat-icon>remove</mat-icon>
													</button>
												</div>
											</div>
											<div class="col-12 p-0 m-0 text-right" *ngFor="let total of cart_totals">
												<!-- <span *ngIf="(total.text !== '0.00' && total.title == 'Discount Total') || !['Discount Total'].includes(total.title)" [ngClass]="isdriver ? 'hide': ''">
													<b>{{total.title }}</b>: {{ total.text | currency }}
												</span> -->
											</div>
										</ng-container>
									</div>
								</ng-container>
							</ul>
						</div>
					</div>
				</ng-container>
			</div>

			<ng-container *ngIf="!credititing">
				<div class="col-12">
					<div class="row">
						<div class="col-md-9">
							<h5 [innerHTML]="selectedorder.address"></h5>
						</div>
						<div class="col-md-3 text-right">
							<ul class="entry-list m-0 p-0 bordered">
								<li>
									<div class="spec-label">PO</div>
									<div class="spec-value">{{ selectedorder.po }}</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="row text-center">
						<div class="ml-auto mr-auto col-12 mt-2 mb-2">
							<button mat-raised-button color="warn" (click)="seleccted_alt = true;">Dropped At Alt. Location</button>
							<div *ngIf="seleccted_alt">
								<mat-form-field appearance="outline" class="ml-2 mr-2">
									<mat-label>Transfer To:</mat-label>
									<mat-select [formControl]="transfertruck">
										<mat-option *ngFor="let truck of trucks" [value]="truck.id">
											{{truck.name }}
										</mat-option>
									</mat-select>
								</mat-form-field>
								<button mat-raised-button color="danger" (click)="altLocation()">Submit</button>
							</div>

						</div>
						<div class="ml-auto mr-auto col-12 mt-2 mb-2">
							<button mat-raised-button color="accent" (click)="cancel(selectedorder.did)">Refused Delivery</button>
						</div>
						<div class="ml-auto mr-auto col-12 mt-2 mb-2">
							<div class="row" *ngIf="selectedorder.ordertype=='11'">

								<ng-container *ngFor="let item of selectedorder.details">
									<div class="col-md-12">
										{{ item.stkcode }} {{ item.itemdesc }}
										<mat-form-field appearance="outline" class="ml-2 mr-2">
											<mat-label>Qty Pick Up</mat-label>
											<input matInput class="text-right" placeholder="Qty Pick Up" [(value)]="item.quantity">
										</mat-form-field>
									</div>
								</ng-container>
							</div>

							<button mat-raised-button color="primary" (click)="delivered(selectedorder, selectedorder.details)">
								<span *ngIf="selectedorder.ordertype=='11'">Picked Up</span>
								<span *ngIf="selectedorder.ordertype!='11'">Delivered</span>
							</button>
						</div>
						<div class="ml-auto mr-auto col-12 mt-2 mb-2" *ngIf="selectedorder.ordertype=='11' && selectedorder.status_id=='31'">

							<button mat-raised-button color="primary" (click)="dropAtHome(selectedorder.did)">
								<span>Return To Warehouse</span>
							</button>
						</div>
					</div>
					<table class="table table-sm">
						<tr>
							<th>Item#</th>
							<th>Description</th>
							<th>Ordered</th>
							<th>Price </th>
						</tr>
						<tr *ngFor="let item of selectedorder.details">
							<td>{{ item.stkcode }}</td>
							<td>{{ item.description }}</td>
							<td>{{ item.ordered }}</td>
							<td>{{ item.unitprice | currency}}</td>
						</tr>
					</table>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="row m-0 p-0" *ngIf="listData.length">
	<button mat-raised-button class="w-100 complete-btn" (click)="completeAndAlert()" [disabled]="!runCompleted && !inbound" [ngClass]="!runCompleted ? 'btn-light' : !inbound ? 'btn-primary' : 'btn-success'">
		<ng-container *ngIf="!inbound">
		Mark run as completed.
		</ng-container>
		<ng-container *ngIf="inbound">
		Run completed, please return to the warehouse.
		</ng-container>
	</button>
</div>
<ng-container *ngFor="let da of listData;trackBy: identify;">
	<div class="row m-0 p-0 small-font border-bottom item-list " *ngIf="da.ispoint=='0'">
		<li class="dd-item dd3-item item-box">
			<div class="dd-handle dd3-handle status-{{da.status_id}}" [ngClass]="{credit: da.ordertype=='11'}">&nbsp;</div>
			<mat-card class="dd3-content p-1 ">
				<mat-card-content>
					<div class="row">
						<ng-container *ngIf="isdispatch">
							<button mat-mini-fab color="accent" aria-label="Remove" (click)="removeFromDispatch(da)">
								<mat-icon>remove</mat-icon>
							</button>
						</ng-container>
						<div class="col-md-5 ml-4 ml-md-2 mr-0 pr-0" [innerHTML]="da.address">
						</div>
						<div class="col-md-12 col-md-4 ml-2 mr-0 pr-0 pl-0">
							<ul class="entry-list m-0 p-0">
								<li>
									<div class="spec-label">PHONE#</div>
									<div class="spec-value"><a href="tel://{{ da.phone }}">{{ da.phone }}</a></div>
								</li>
								<li>
									<div class="spec-label" *ngIf="da.ordertype == 11">CREDIT#</div>
									<div class="spec-label" *ngIf="da.ordertype != 11">ORDER#</div>
									<div class="spec-value">{{ da.invoice }}</div>
								</li>
								<li>
									<div class="spec-label">PO</div>
									<div class="spec-value">{{ da.po }}</div>
								</li>
								<li>
									<div class="spec-label">Items</div>
									<div class="spec-value">
										<ng-container *ngFor="let dets of da.details">
											{{dets['stkcode']}} x {{dets.quantity}} <br>
										</ng-container>
									</div>
								</li>
								<li *ngIf="da.payterms == 'COD'">
									<div class="spec-label">Terms</div>
									<div class="spec-value">{{da.payterms}}</div>
								</li>
								<li>
									<div class="spec-label">Status</div>
									<div class="spec-value">{{ da.status }}</div>
								</li>
								<!-- <li *ngIf="da.needs_pod">
									<div class="spec-label simple">SIMPLETIRE LOCAL DELIVERY</div>
									<div class="spec-value simple">***Needs POD Signature***</div>
								</li> -->
							</ul>
						</div>
						<div class="col-md-4 col-md-2 ml-0 mr-0 text-right text-md-left mt-4 mb-3 ">
							<button mat-mini-fab color="accent" aria-label="View / Update Status" (click)="viewOrder(da)">
								<mat-icon>sync_alt</mat-icon>
							</button>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</li>
	</div>
</ng-container>
