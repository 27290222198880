import { Component, OnInit, OnChanges } from '@angular/core';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-price-level-pricing',
	templateUrl: './price-level-pricing.component.html',
	styleUrls: ['./price-level-pricing.component.scss']
})
export class PriceLevelPricingComponent implements OnInit, OnChanges {
	priceLevelForm: FormGroup;
	searchTerm = new FormControl('');
	priceLevels: any[] = [];
	categories: any[] = [];
	allResults: any[] = []; // Store all results initially fetched
	results: any[] = []; // Store the filtered results
	showNewPriceLevelInput = false;

	newPriceLevelName = '';
	newPriceLevelId = ''; // New property for price level ID
	brands: any = [];
	allBrands: any = [];
	brandOptions: any = [];
	isSearching: boolean = false;
	showHowTo: boolean = false;


	constructor(
		private inventoryService: InventoryService,
		private globalSearchService: GlobalSearchService,
		private fb: FormBuilder,
		private modalService: NgbModal
	) {
		this.priceLevelForm = this.fb.group({
			pricelevels: [
				[]
			],
			categories: [
				[]
			],
			brands: [
				[]
			],
		});

	}

	openHowTo(content: any): void {
	  this.modalService.open(content, { size: 'lg', scrollable: true });
	}

	ngOnInit(): void {
		this.getInitialData();
		this.onChanges();

	}

	getInitialData(): void {
		// Initialize price levels, categories, and results from the service
		this.isSearching = true;
		this.inventoryService.getPriceLevels().subscribe((data) => {
			this.priceLevels = data.pricelevel;
			this.categories = data.categories;
			this.allResults = data.results; // Assuming results are fetched initially
			this.results = this.allResults; // Initially, no filter is applied
			this.brands = data.brands;
			this.allBrands = data.brands;

			this.brandOptions = this.brands.map(b => ({
				id: b.manufacturer_id,
				name: b.brand_name
			})).filter((value, index, self) =>
				index === self.findIndex(v => v.id === value.id)
			);
			this.isSearching = false;
			this.filterResults();
		});
	}

	ngOnChanges(): void {
		this.filterAllProductLines(this.searchTerm.value);
		this.onChanges();
	}

	onChanges(): void {
		this.priceLevelForm.get('pricelevels')?.valueChanges.subscribe(() => {
			this.filterResults();
		});
		this.priceLevelForm.get('categories')?.valueChanges.subscribe(() => {
			this.filterResults();
		});
		this.priceLevelForm.get('brands')?.valueChanges.subscribe((newvalue) => {

			this.filterResults();
		});
	}

	filterAllProductLines(keyword: string): void {
		const lower = keyword?.toLowerCase() || '';

		this.brands = this.allBrands.map(brand => {
			const filteredLines = brand.product_lines.filter((line: any) => {
				return (
					(line.line_description || '').toLowerCase().includes(lower) ||
					(line.line_field || '').toLowerCase().includes(lower) ||
					(line.price_level_id || '').toLowerCase().includes(lower)
				);
			});

			return {
				...brand,
				original_product_lines: [...brand.product_lines],
				product_lines: filteredLines,
				expanded: true // auto-expand filtered
			};
		}).filter(brand => brand.product_lines.length > 0);
	}

	filterResults(): void {
		const selectedPriceLevels = this.priceLevelForm.get('pricelevels')?.value || [];
		const selectedCategories = this.priceLevelForm.get('categories')?.value || [];
		const selectedBrands = this.priceLevelForm.get('brands')?.value || [];

		this.brands = this.allBrands
			.map(brand => {
				const matchesBrand = selectedBrands.length === 0 || selectedBrands.includes(brand.manufacturer_id);

				if (!matchesBrand) {
					return { ...brand, product_lines: [] };
				}

				const filteredLines = brand.product_lines.filter(line => {
					const matchesLevel =
						selectedPriceLevels.length === 0 || selectedPriceLevels.includes(line.price_level_id);

					const matchesCategory =
						selectedCategories.length === 0 || selectedCategories.includes(line.line_field);

					return matchesLevel && matchesCategory;
				});

				return {
					...brand,
					product_lines: filteredLines
				};
			})
			.filter(brand => brand.product_lines.length > 0);
	}

	toggleNewPriceLevel(): void {
		this.showNewPriceLevelInput = !this.showNewPriceLevelInput;
	}

	createNewPriceLevel(): void {
		if (this.newPriceLevelName.trim() === '' || this.newPriceLevelId.trim() === '') {
			this.globalSearchService.showNotification('Price level name and ID cannot be empty', 'error', 'bottom', 'right');
			return;
		}

		const payload = {
			name: this.newPriceLevelName,
			pricelevel: this.newPriceLevelId
		};

		this.inventoryService.createPriceLevel(payload).subscribe(() => {
			this.globalSearchService.showNotification('New price level created', 'success', 'bottom', 'right');
			this.getInitialData(); // Refresh the data to include the new price level
			this.showNewPriceLevelInput = false; // Hide the input after saving
			this.newPriceLevelName = ''; // Reset the input
			this.newPriceLevelId = ''; // Reset the input
		});
	}

	updateAllChildren(brand: any): void {
		const value = brand.discountpercent;
		if (!value || isNaN(parseFloat(value))) return;

		brand.product_lines.forEach((line: any) => {
			line.discountpercent = value;
			this.onPriceBlur(line.price_level_id, line.line_field, value, line.manufacturer_id, line);
		});
	}


	applyBulkDiscount(brand: any): void {
		const bulk = brand._bulkDiscount;

		for (const result of brand.product_lines) {
			result.discountpercent = bulk;
		}

		this.inventoryService.savePriceLevelBulk(brand).subscribe(result => {
			this.globalSearchService.showNotification('Updated', 'success', "bottom", "right")
			this.getInitialData();
		})
	}


	savePriceLevel(level: string, category: string, discount: string, manufacturer_id: string): void {
		let line_description = '';

		for (const brand of this.brands) {
			if (brand.manufacturer_id === manufacturer_id) {
				const match = brand.product_lines.find(
					line => line.price_level_id === level && line.line_field === category
				);
				if (match) {
					line_description = match.line_description;
					break;
				}
			}
		}

		const payload = {
			price_type: level,
			category_id: category,
			manufacturer_id: manufacturer_id,
			discountpercent: discount,
			line_description: line_description
		};

		this.inventoryService.savePriceLevel(payload).subscribe(() => {
			this.showSuccessMessage(level, category, discount);
			this.getInitialData();
		});
	}


	calculateMarkup(discount: string): number {
		const d = parseFloat(discount);

		// Handle NaN or 0 directly
		if (isNaN(d) || d === 0) return 0;

		if (d < 0) {
			// It's a markup, display as entered (e.g., -20 → 20% markup)
			return parseFloat(Math.abs(d).toFixed(2));
		}

		if (d >= 100) {
			// Invalid discount
			return 0;
		}

		// Discount converted to markup multiplier
		const markup = (100 / (100 - d)) - 1;
		return parseFloat((markup * 100).toFixed(2)); // multiply back to percent
	}


	isValidDiscount(discount: string): boolean {
		const d = parseFloat(discount);

		return !isNaN(d) && d !== 0;
	}

	isMarkup(value: string): boolean {
		const num = parseFloat(value);
		return !isNaN(num) && num < 0;
	}

	showSuccessMessage(level: string, category: string, discount: string): void {
		this.globalSearchService.showNotification('Updated', 'success', 'bottom', 'right')
	}

	onPriceBlur(priceLevelId: string, lineField: string, discount: any, manufacturerId: string, result: any): void {

		this.savePriceLevel(priceLevelId, lineField, discount, manufacturerId);
		result._clicked = true;
		setTimeout(() => {
			result._clicked = false;
		}, 300);
	}




}