<span *ngIf="getting">
	<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
		<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
	</svg>
</span>

<span *ngIf="profile_cards && !profile_cards.length && !getting">No Cards On File</span>

<div class="col-12 mt-2" *ngIf="hasprofile" [ngClass]="type === 'orderentry' ? ' text-left' :' text-center'">
	<!-- <ng-container *ngIf="type === 'orderentry' && config.env.package === 'beauty'; else radioButtons">
		<mat-form-field appearance="outline" class="full-width ">
			<mat-label>Select a card on file</mat-label>
			<mat-select [formControl]="selected_card" (selectionChange)="selectCard($event.value)">
				<mat-option *ngFor="let card of profile_cards" [value]="card">
					<b><span [innerHTML]="card.name"></span></b> - {{ card.number }} Exp: {{ card.expiry_month }}/{{ card.expiry_year }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</ng-container> <ng-template #radioButtons></ng-template> -->

		<mat-radio-group aria-label="Select a card on file" class="text-left w-100" [formControl]="selected_card">
			
			<mat-radio-button *ngFor="let card of profile_cards" [value]="card" [color]="'accent'" (change)="updatePaymentType(card.payment_method)" class="ml-2 mr-2" (click)="selectCard(card)">
				<div class="card-details" [class.highlight]="card.branchcode === customer.branchcode">
				  <b>
					<span *ngIf="card.branchcode; else noShipTo">
					  {{ card.branchcode }}<span class="badge"></span>
					</span>
					<ng-template #noShipTo>
					  <span class="text-muted">No Ship To Selected</span>
					</ng-template>
					| <span [innerHTML]="card.name"></span>
				  </b>
				  <br>
				  {{ card.number }} Exp: {{ card.expiry_month }}/{{ card.expiry_year }}
				</div>


			</mat-radio-button>
		</mat-radio-group>

</div>
