<!-- Modal Template -->
<ng-template #howtoContent let-modal>
	<div class="modal-body">
		<mat-accordion class="user-guide-accordion">
			<!-- Creating a New Price Level -->
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>➕ Creating a New Price Level</mat-panel-title>
				</mat-expansion-panel-header>
				<ol>
					<li>Click the <strong>Add New Price Level</strong> toggle button.</li>
					<li>Enter the <strong>Name</strong> and <strong>ID</strong> of the new level.</li>
					<li>Click <strong>Save</strong> to create it.</li>
					<li>The list will refresh to show the new price level.</li>
				</ol>
			</mat-expansion-panel>

			<!-- Searching Product Lines -->
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>🔍 Searching Product Lines</mat-panel-title>
				</mat-expansion-panel-header>
				<ul>
					<li>Type into the <strong>Search product lines</strong> input field.</li>
					<li>The system filters lines live as you type.</li>
					<li>A loading spinner will appear while results are being fetched.</li>
				</ul>
			</mat-expansion-panel>

			<!-- Filtering Results -->
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>📂 Filtering Results</mat-panel-title>
				</mat-expansion-panel-header>
				<ul>
					<li>Use the dropdowns to filter by <strong>Price Levels</strong>, <strong>Categories</strong>, and <strong>Brands</strong>.</li>
					<li>Results are updated instantly when a filter is changed.</li>
				</ul>
			</mat-expansion-panel>



			<!-- Editing Discounts -->
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>✏️ Editing Discounts</mat-panel-title>
				</mat-expansion-panel-header>
				<ul>
					<li>Type a new discount next to the product line.</li>
					<li>Leaving the input field automatically saves it.</li>
					<li><code>-20</code> means a 20% markup. Negative = markup.</li>
				</ul>
			</mat-expansion-panel>

			<!-- Bulk Discount Application -->
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>📉 Bulk Discount Application</mat-panel-title>
				</mat-expansion-panel-header>
				<ol>
					<li>Enter a value in the brand's <strong>Bulk Discount</strong> field.</li>
					<li>Click <strong>Apply</strong> to update all current product lines.</li>
				</ol>
				<p class="text-muted">⚠️ <strong>Note:</strong> New product lines added later will <em>not</em> automatically receive this bulk discount. You must re-apply it.</p>
			</mat-expansion-panel>

			<!-- Discount Validations -->
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>✅ Discount Validations</mat-panel-title>
				</mat-expansion-panel-header>
				<ul>
					<li>Discount must be a valid number.</li>
					<li>Cannot be 0 or above 100.</li>
					<li>Negative values are treated as markup.</li>
				</ul>
			</mat-expansion-panel>

			<!-- Markup Calculations -->
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>📈 Markup Calculations</mat-panel-title>
				</mat-expansion-panel-header>
				<ul>
					<li>A value like <code>-25</code> represents a 25% markup.</li>
					<li>The system will automatically show the markup % next to the discount.</li>
				</ul>
			</mat-expansion-panel>

		</mat-accordion>
	</div>

</ng-template>




<div class="card mb-3">
	<div class="card-header pb-2">

		<!-- New Price Level Input Section -->
		<div class="d-flex justify-content-start mt-3 mb-3">
			<a href="#" (click)="$event.preventDefault();openHowTo(howtoContent)">Click to view instructions</a>
		</div>
		<div class="d-flex justify-content-end mt-3 mb-3">

			<button mat-raised-button color="primary" (click)="toggleNewPriceLevel()">Create New Price Level</button>
		</div>

		<div *ngIf="showNewPriceLevelInput" class="row mt-3">
			<div class="row gx-3 gy-2 align-items-center mb-4">
				<div class="col-4">
					<mat-form-field appearance="fill" class="full-width">
						<mat-label>New Price Level Name</mat-label>
						<input matInput [(ngModel)]="newPriceLevelName">
					</mat-form-field>
				</div>
				<div class="col-4">
					<mat-form-field appearance="fill" class="full-width">
						<mat-label>New Price Level ID</mat-label>
						<input matInput [(ngModel)]="newPriceLevelId">
					</mat-form-field>
				</div>
				<div class="col-4">
					<button mat-raised-button color="primary" (click)="createNewPriceLevel()">Save</button>
				</div>
			</div>
		</div>
		<div class="row gx-3 gy-2 align-items-end">
			<div class="col-md-4">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Price Level</mat-label>
					<mat-select multiple [formControl]="priceLevelForm.get('pricelevels')" appSelectAll>
						<mat-option *ngFor="let level of priceLevels" [value]="level.price_level_id">
							{{ level.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="col-md-4">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Product Line</mat-label>
					<mat-select multiple [formControl]="priceLevelForm.get('categories')" appSelectAll>
						<mat-option *ngFor="let category of categories" [value]="category.line_field">
							{{ category.line_field }} - {{ category.line_description }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="col-md-4">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Brand</mat-label>
					<mat-select multiple [formControl]="priceLevelForm.get('brands')" appSelectAll>
						<mat-option *ngFor="let brand of brandOptions" [value]="brand.id">
							{{ brand.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="card-body" id="open-orders">
			<div id="order-parent">
				<!-- FILTER INPUT -->
				<div class="mb-3">
					<mat-form-field appearance="outline">
						<mat-label>Search product lines</mat-label>
						<input matInput [formControl]="searchTerm" placeholder="Search product lines..." (input)="filterAllProductLines($event.target.value)" />
						<mat-icon matSuffix *ngIf="!isSearching">search</mat-icon>
						<mat-progress-spinner matSuffix *ngIf="isSearching" mode="indeterminate" diameter="20" strokeWidth="3">
						</mat-progress-spinner>
					</mat-form-field>
				</div>


				<div *ngFor="let brand of brands" class="mb-5">

					<!-- BRAND HEADER -->
					<div class="brand-bar flex-column flex-md-row align-items-start align-items-md-center gap-3">
						<h5 class="brand-header mb-1 mb-md-0">
							{{ brand.brand_name }}
						</h5>

						<div class="brand-input-wrapper">
							<input type="text" class="brand-input" [(ngModel)]="brand.discount_percent" placeholder="Set All Discounts" (keydown.enter)="applyBulkDiscount(brand)" />
							<span class="percent-suffix">%</span>
							<mat-icon class="brand-icon" (click)="applyBulkDiscount(brand)" aria-label="Apply bulk discount">tune</mat-icon>
						</div>
					</div>

					<!-- PRODUCT LINES -->
					<div class="product-line-condensed-wrap" *ngIf="brand.product_lines.length > 0">
						<div class="product-line-condensed-header">
							<small>{{ brand.product_lines.length }} product lines</small>
							<mat-icon class="toggle-icon" (click)="brand.expanded = !brand.expanded" aria-label="Toggle product lines">
								{{ brand.expanded ? 'expand_less' : 'expand_more' }}
							</mat-icon>
						</div>

						<div class="product-line-condensed-body" *ngIf="brand.expanded !== false">
							<div class="product-condensed-row" *ngFor="let result of brand.product_lines; let i = index">

								<div class="condensed-pill-group">
									<span class="pill">{{ result.price_level_id }}</span>
									<span class="pill alt">{{ result.line_field }}</span>
								</div>

								<div class="condensed-desc" [matTooltip]="result.line_description">
									{{ result.line_description }}
								</div>

								<!-- Discount Input -->
								<div class="condensed-input-group">
									<input #inputRef type="text" class="custom-input small" [(ngModel)]="result.discountpercent" (keydown.enter)="onPriceBlur(result.price_level_id, result.line_field, result.discountpercent, result.manufacturer_id, result)" />

									<span class="percent-suffix">%</span>
								</div>

								<!-- Status -->
								<div class="condensed-status">
									<ng-container *ngIf="isValidDiscount(result.discountpercent); else placeholder">
										<mat-icon inline [ngClass]="{
											'markup-up': isMarkup(result.discountpercent),
											'markup-down': !isMarkup(result.discountpercent)
										}">
											{{ isMarkup(result.discountpercent) ? 'arrow_upward' : 'arrow_downward' }}
										</mat-icon>
										<strong>{{ result.discountpercent }}%</strong>
									</ng-container>
									<ng-template #placeholder>
										<!-- Keeps space reserved -->
										<mat-icon style="visibility: hidden;">arrow_upward</mat-icon>
										<strong style="visibility: hidden;">000.0%</strong>
									</ng-template>
								</div>


								<!-- Save Button -->
								<button class="save-btn small" type="button" (click)="onPriceBlur(result.price_level_id, result.line_field, result.discountpercent, result.manufacturer_id, result)" [ngClass]="{ 'clicked': result._clicked }" aria-label="Save discount">
									<mat-icon>save</mat-icon>
								</button>

							</div>
						</div>
					</div>

					<!-- Fallback -->
					<div *ngIf="brand.product_lines.length === 0" class="text-muted text-center small">
						No product lines available.
					</div>

				</div>
			</div>
		</div>
	</div>

	<div id="floater-container"></div>