import { Component, SimpleChanges, OnChanges, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, EventEmitter, Pipe, PipeTransform, Directive, HostListener } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors, FormControl, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, DatePipe } from '@angular/common'
import { Subject, Observable, } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { CustomerService } from '../../../services/customer.service';
import { OrdersService } from '../../../services/orders.service';
import { InventoryService } from '../../../services/inventory.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { UsersService } from '../../../services/users.service';
import { DispatchService } from '../../../services/dispatch.service';
import { OmsService } from '../../../services/oms.service';
import { E } from '@angular/cdk/keycodes';
import { isNull } from 'util';
import { CouponService } from 'app/services/coupon.service';
import { compareDesc } from 'date-fns';
import { info } from 'console';

@Component({
	selector: 'app-order-review',
	templateUrl: './order-review.component.html',
	styleUrls: ['./order-review.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('250ms ease-in')),
			transition('active => inactive', animate('250ms ease-out')),
		]),
		trigger('openClose', [
			state(':enter', style({ height: '*' })),
			state(':leave', style({ height: '0px' })),
			transition('false <=> true', animate(500))
		]),
		trigger('filterAnimation', [
			transition(':enter, * => 0, * => -1', []),
			transition(':increment', [
				query(':enter', [
					style({ opacity: 0, width: 0 }),
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 1, width: '*' })),
					]),
				], { optional: true })
			]),
			transition(':decrement', [
				query(':leave', [
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 0, width: 0 })),
					]),
				])
			]),
		]),
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0', width: '0' }),
				animate(200, style({ height: '*', width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0, })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class OrderReviewComponent implements OnInit {
	@Input() customer: any = false;
	@Input() cart_items: any = false;
	@Input() cart_totals: any = false;
	@Input() charges: any = false;
	@Input() vehicle: any = false;

	@Output() updateCustomer = new EventEmitter < any > ();
	@Output() updatePrint = new EventEmitter < any > ();
	@Output() updateCart = new EventEmitter < any > ();
	@Output() updateAdjustment = new EventEmitter < any > ();
	@Output() refreshCart = new EventEmitter < any > ();
	@Output() removeItem = new EventEmitter < any > ();
	@Output() freight_charge = new EventEmitter < any > ();
	@Output() updateVehicle = new EventEmitter < any > ();
	@ViewChild('purchasedetails') purchasedetailsRef: ElementRef;
	@ViewChild('couponModal') couponModalRef: ElementRef;

	@ViewChild('vehicleModal') vehicleModalRef: ElementRef;
	@ViewChild('taxdetails') taxdetailsRef: ElementRef;
	editbilling = false
	editshipping = false
	editsettings = false
	chargeTypeSwitch = new UntypedFormControl('');
	customerForm: UntypedFormGroup;
	noteselector = new UntypedFormControl(0);
	couponFormControl = new UntypedFormControl('');
	date_selected: any = false;
	purchase_items: any = [];
	customerdata: any = false;
	forceTax = new UntypedFormControl(false);
	hasNegative: boolean = false;
	customerEditAllowed = [
		'debtorno',
		'branchcode',
		'name',
		'address1',
		'address2',
		'address3',
		'address4',
		'address5',
		'address6',
		'contactname',
		'brname',
		'braddress1',
		'braddress2',
		'braddress3',
		'braddress4',
		'braddress5',
		'braddress6',
		'email',
		'salesman',
		'salestype',
		'defaultshipvia',
		'phoneno',
		'faxno',
		'specialinstructions',
		'defaultlocation',
		'paymentterms',
		'tax_ref',
		'taxgroupid',
		'area',
		'datewanted',
		'enteredby',
		'takenby',
	]

	addonfields: [
		'datewanted',
		'enteredby',
		'takenby',
		'freightcost',
	];

	user: any = [];
	today = new Date();
	tomorrow = new Date();
	shipvia: any = false;
	taxgroups: any = [];
	salespeople: any = [];
	terms: any = [];
	zones: any = false;
	locations: any = [];
	holdreasons: any = [];
	salestypes: any = [];
	quote = [
		{ label: 'Order', value: '0' },
		{ label: 'Quote', value: '1' }
	]
	config: any = [];
	onetimecard = false;
	routes: any = [];
	ship_options: any = [
		{ value: '0', viewValue: '***Ship ASAP***' },
		{ value: '1', viewValue: 'Ship when available' },
		{ value: '2', viewValue: '' },
	];
	shipping_notes = false;
	selected_ship_option: any = '';

	selected_card = new UntypedFormControl('');
	adjustRef = new UntypedFormControl('');
	adjustTitleRef = new UntypedFormControl('');
	recalculated = false;
	couponData: any = false;
	historic_cart: any;
	itemdetails: any = false;
	doNotPrint = new UntypedFormControl(false);
	editsallowed: any = {
		printorder: true,
		discounts: true,
		editar: true,
		editbilling: true,
		editshipping: true,
		editcontact: true,
		editprofile: true,
		editnotes: true,
		takepayment: true,
	}

	preOrderOpenState = false;
	additionaltext: any = '';
	additionalcharge: any = 0;
	adjustedTotal: any = 0;
	original_loaded = false;
	init_editing: {} = {
		dnote: '',
		inote: '',
		ref: '',
		shipvia: '',
		freightcost: '',
		trackingnumber: ''
	}
	coupon: any = false;
	arpreauth: boolean = false;
	issalesman: boolean = false;
	discountAll: boolean = false;
	discountAllRef: any = '';
	custNotes: any = [];
	zeroTax = new UntypedFormControl(false);
	vehicleDetsForm: UntypedFormGroup;
	resetTireGuide: boolean = false;
	vehicle_tab: number = 0;
	custVehicles: any = [];
	editing_vehicle: any = false;
	vehicleModalOpen: boolean = false;
	inventorysearching: any = false;
	swapitems: any = false;
	
	constructor(private dispatchService: DispatchService, private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, public omsService: OmsService, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal, public usersService: UsersService, private couponService: CouponService) {

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;

			if (this.config.arpreauth) {
				this.arpreauth = this.config.arpreauth;
				this.chargeTypeSwitch.setValue('card_onfile');
			}

			this.globalSearchService.user.subscribe((results: any) => {

				if (results) {
					this.user = results.user;

					if (results.user.issalesman) {
						this.editsallowed = this.config.salesmanAllowed
						this.issalesman = true;

						//this is do not auto print
						if (this.config.salesmanAllowed && !this.config.salesmanAllowed.printorder) {
							if (this.config.env.client === 'aaprod') {
								this.doNotPrint.setValue(false);
							} else {
								this.doNotPrint.setValue(true);
							}
						}
					}

					if (this.customer.defaultlocation) {
						if (results.user.defaultlocation != this.customer.defaultlocation) {
							this.customer.defaultlocation = results.user.defaultlocation;
							this.customerForm.get('defaultlocation').setValue(results.user.defaultlocation.loccode);
						}
					}
				}
			});
		});

		this.dispatchService.getRoutes().subscribe(r => {
			this.routes = r;
		});

		this.tomorrow.setDate(this.tomorrow.getDate() + 1);

	}

	ngOnInit(): void {


		//globals
		this.globalSearchService.taxgroups.subscribe(async (results: any) => {
			this.taxgroups = results;
		});

		this.globalSearchService.salespeople.subscribe(async (results: any) => {
			this.salespeople = results;
		});

		this.globalSearchService.terms.subscribe(async (results: any) => {
			this.terms = results;
		});

		this.globalSearchService.holdreasons.subscribe(async (results: any) => {
			this.holdreasons = results;
		});

		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
		});
		this.globalSearchService.zones.subscribe(async (results: any) => {
			this.zones = results;
		});

		this.globalSearchService.salestypes.subscribe(async (results: any) => {
			this.salestypes = results;
		});

		this.zeroTax.valueChanges.subscribe(newValue => {
			//	this.forceTax.setValue(true);
			const data = {
				debtorno: this.customerdata.debtorno,
				branchcode: this.customerdata.branchcode,
				defaultlocation: this.customerdata.defaultlocation,
				forcetax: this.zeroTax.value
			}

			this.orderService.reloadItemsWIthForcedTaxZero(data).subscribe((results: any) => {
				this.updateCart.emit(true);
				this.refreshCart.emit(true);
			});
		});
		this.getCustomerNotes();
		this.setVehicleForm();
		this.checkCoupon();
	}

	toggleTax(event: Event): void {
		const checkbox = event.target as HTMLInputElement;
		this.zeroTax.setValue(checkbox.checked);
	}


	toggleOneTimeCard() {
		this.onetimecard = (this.onetimecard) ? false : true;
		if (this.onetimecard) {
			this.chargeTypeSwitch.setValue('card_onfile');
		}
	}

	checkTermIndicator(termsindicator: string): boolean {
		const term = this.terms.find(t => t.termsindicator === termsindicator);
		return term ? term.cc_process === '1' : false;
	}

	getFormattedDate(date) {
		const year = date.getFullYear();
		let month = (1 + date.getMonth()).toString();
		month = month.length > 1 ? month : '0' + month;
		let day = date.getDate().toString();
		day = day.length > 1 ? day : '0' + day;
		return month + '/' + day + '/' + year;
	}

	shippingOptionChange(event: any) {
		let currentComment = this.customerForm.get('comments').value;
		if (currentComment === null) {
			currentComment = '';
		}

		if (event.value) {
			let new_selection = this.ship_options[event.value].viewValue;

			if (event.value == 0 || event.value == 1) {
				this.customerForm.get('datewanted').setValue(this.today);

				if (this.date_selected) {
					currentComment = currentComment.replace(this.date_selected, '').trim();
					this.date_selected = false
				}

			} else {
				this.customerForm.get('datewanted').setValue(this.tomorrow);
				const date = new Date(this.customerForm.get('datewanted').value);
				const parsed_date = this.getFormattedDate(date)
				new_selection = 'Wanted ' + parsed_date;
				this.date_selected = 'Wanted ' + parsed_date;
			}

			//if comment empty just add the current selected ship option
			if (currentComment === '') {

				this.customerForm.get('comments').setValue(new_selection + '\n');
				this.selected_ship_option = new_selection;

				//if comment is not empty
			} else {
				//if no selected ship option
				if (this.selected_ship_option === '') {
					//Add the selected ship option to existing current comment;
					this.customerForm.get('comments').setValue(new_selection + '\n' + currentComment);
					this.selected_ship_option = new_selection;
					//if previous selected ship option
				} else {
					///date wanted specifics
					if (event.value == 2) {
						const date = new Date(this.customerForm.get('datewanted').value);
						const parsed_date = this.getFormattedDate(date)
						new_selection = 'Wanted ' + parsed_date;
					}
					//strip old selected ship option and add new one
					currentComment = currentComment.replace(this.selected_ship_option, '').trim();
					this.customerForm.get('comments').setValue(new_selection + '\n' + currentComment);
					this.selected_ship_option = new_selection;
				}
			}

			//'none' value handling, strip the last actice ship_option
		} else {
			if (this.selected_ship_option !== '') {
				currentComment = currentComment.replace(this.selected_ship_option, '').trim();
				this.customerForm.get('comments').setValue(currentComment);
				this.selected_ship_option = '';
			}
		}
	}

	openShipNotes() {
		this.shipping_notes = (this.shipping_notes) ? false : true;
	}

	getCustomerNotes() {
		if (this.customer?.debtorno) {
			const data = {
				debtorno: this.customer.debtorno,
				branch: this.customer.branchcode
			}
			this.customerService.getCustomerNotes(data).subscribe(notes => {
				this.custNotes = notes.filter(note => note.note_code.toUpperCase() === "ORDER");
			});
		}
	}

	updateTotals() {
		this.refreshCart.emit(true);
	}
	
	attachCard(data: any) {

		//change order terms. TODO: need to filter out terms instead
		if (this.config.env.package == 'tires') {
			this.customerForm.get('paymentterms').setValue('CCN');
		}
		
		if (data) {
			if(data.requested_card) {
				this.customerForm.get('payment_method').setValue(data.requested_card);
				this.onetimecard = false;
			} else {
				this.customerForm.get('payment_method').setValue(data)
			}
		}
	}


	
	itemSearch(item): any {
		const data = {
			keywords: item.inputValue,
			limit: 10,
			sort: 'SKU|ASC',
			customer: this.customer,
			filters: [],
			customer_items_only: false,
			vendor_items_only: false,
			type: '30',
			user: this.user.user
		};

		if (this.inventorysearching) {
			this.inventorysearching.unsubscribe();
		}

		this.inventorysearching = this.orderService.getItemSearch(data).subscribe((items: any) => {
			this.swapitems = items;
		});
	}

	selectSwapItem(item: any, fromitem: any) {
		let data = {
			cartid: fromitem.cartid,
			stockid: item.stockid,
			desription: item.description,
			price: item.price,
			quantity: fromitem.quantity
		}

		let updateitem = fromitem;

		updateitem.description = item.description;
		updateitem.stockid = item.stockid;
		updateitem.price = item.price;
		updateitem.quantity = fromitem.quantity;

		fromitem.inputValue = '';
		item.inputValue = '';
		item.switching = false;

		this.orderService.swapItem(data).subscribe((items: any) => {
			this.swapitems = false;
			this.refreshCart.emit(true);
		});

	}

	removePaymentMethod() {
		this.customerForm.get('payment_method').setValue(false)
	}


	updateItem(data: any, item) {
		item.purchdata = data.purchdata;
	}
	updateAdj() {
		this.additionalcharge = this.adjustRef.value;
		this.additionaltext = this.adjustTitleRef.value;
		this.cart_totals.forEach((total) => {
			if (total.code == 'total') {
				this.adjustedTotal = total.text;
			}
		});
		this.adjustedTotal = parseFloat(this.adjustedTotal) + parseFloat(this.additionalcharge);
		const adjust = { additionaltext: this.additionaltext, additionalcharge: this.additionalcharge };
		this.updateAdjustment.emit(adjust);
	}
	loadCustomer() {
		//let location = (this.user.user.defaultlocation) ? this.user.user.defaultlocation.loccode : this.locations[0].loccode;
		this.globalSearchService.user.subscribe((results: any) => {
			this.user = results.user;

			this.customerService.getDebtorWithBranch(this.customer.debtorno, this.customer.branchcode).subscribe(async (r: any) => {
				this.customerdata = r;

			});
		});

	}

	viewPurchOrderOptions(line: any) {
		line.allownegative = true;
		const data = { cart_id: line.cart_id, stockid: line.stockid }
		this.orderService.getPurchDataFromLine(data).subscribe((results: any) => {
			this.purchase_items.push(results);
			this.modalService.open(this.purchasedetailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result: any) => {

			}, (reason) => {
				this.purchase_items = [];
			});
		});
	}


	setPurchOrderOptions(cart_id: any, selected: any) {
		const data = { cart_id: cart_id, options: selected }
		this.orderService.setPoOptions(data).subscribe((results: any) => {
			this.purchase_items = [];
			this.modalService.dismissAll();
		});

		this.updateCart.emit(true);
	}

	changeBranch(branchcode: any) {
		const originalBranch = this.customer.branchcode;
		const ref = this.customerForm.get('reference').value


		this.customerService.getDebtorWithBranch(this.customer.debtorno, branchcode).subscribe(async (results: any) => {
			this.customer = results.customer;
			this.customerdata = results;

			if (results.customer.defaultlocation) {
				if (this.customerForm.get('defaultlocation').value != results.customer.defaultlocation) {
					this.customerForm.get('defaultlocation').setValue(results.customer.defaultlocation);
				}
			}


			this.loadData();

			this.customerForm.get('branchcode').setValue(branchcode)
			this.orderService.updateCartShipto(this.customer.debtorno, originalBranch, this.customer.branchcode).subscribe(async (branchrresults: any) => {
				this.customerForm.get('reference').setValue(ref)
			});
		});
	}

	getRouteName(id: any) {
		if (id) {
			const route = this.routes.filter(r => {
				return r.route_Id == id;
			})[0]

			if (route) {
				return route.route_description;
			}
			return '';
		} else {
			return '';
		}
	}

	stopProp(event) {
		event.stopPropagation();
	}

	loadData() {
		let trackingnumber = '';
		let dnote = '';
		let inote = '';
		let ref = '';
		let shipvia = '';
		let freightcost = '';
		let card = false;

		this.loadCustomer();
		//note already set re apply it
		if (this.customerForm) {
			if (this.customerForm.get('comments').value != '') {
				dnote = this.customerForm.get('comments').value;
			} else {
				dnote = this.customer.dispatchnote;
			}
			if (this.customerForm.get('notes').value != '') {
				inote = this.customerForm.get('notes').value;
			}

			if (this.customerForm.get('payment_method').value) {
				card = this.customerForm.get('payment_method').value;
			}
		}

		//reset form based on existing order details

		if (this.customer.editingorder) {
			dnote = this.customer.editingorder.notes;
			inote = this.customer.editingorder.comments;
			ref = this.customer.editingorder.customerref;
			shipvia = this.customer.editingorder.shipvia;
			freightcost = this.customer.editingorder.freightcost;
			trackingnumber = this.customer.editingorder.trackingnumber
			//preselected payment method
			if (this.customer.preselected) {
				card = this.customer.preselected;
			}

		}

		let location = this.customer.defaultlocation;
		if (this.user) {
			this.customer.defaultlocation = this.user.defaultlocation.loccode;
			location = this.user.defaultlocation.loccode;
		}


		this.customerForm = this.fb.group({
			debtorno: [this.customer.debtorno, Validators.required],
			branchcode: [this.customer.branchcode, Validators.required],
			name: [this.customer.name, Validators.required],
			address1: [this.customer.address1, Validators.required],
			address2: [this.customer.address2],
			address3: [this.customer.address3, Validators.required],
			address4: [this.customer.address4, Validators.required],
			address5: [this.customer.address5, Validators.required],
			address6: [this.customer.address6],
			brname: [this.customer.brname, Validators.required],
			braddress1: [this.customer.braddress1, Validators.required],
			braddress2: [this.customer.braddress2],
			braddress3: [this.customer.braddress3, Validators.required],
			braddress4: [this.customer.braddress4, Validators.required],
			braddress5: [this.customer.braddress5, Validators.required],
			braddress6: [this.customer.braddress6],
			reference: [(this.init_editing['ref'] == '') ? ref : this.init_editing['ref']],
			paymentterms: [this.customer.paymentterms, Validators.required],
			datewanted: [this.today, Validators.required],
			email: [this.customer.email],
			defaultlocation: [location, Validators.required],
			salesman: [this.customer.salesman, Validators.required],
			phoneno: [this.customer.phoneno],
			enteredby: [this.user.userid, Validators.required],
			takenby: [this.user.userid, Validators.required],
			defaultshipvia: [(this.init_editing['shipvia'] == '') ? this.customer.defaultshipvia : this.init_editing['shipvia'], Validators.required],
			salestype: [this.customer.salestype, Validators.required],
			freightcost: [(this.init_editing['freightcost'] == '') ? 0.00 : this.init_editing['freightcost']],
			comments: [(this.init_editing['dnote'] == '') ? dnote : this.init_editing['dnote']],
			notes: [(this.init_editing['inote'] == '') ? inote : this.init_editing['inote']],
			payment_method: [card],
			doNotPrint: [this.doNotPrint.value],
			trackingnumber: [(this.init_editing['trackingnumber'] == '') ? trackingnumber : this.init_editing['trackingnumber']]
			//quote: ['0', Validators.required]
		});

		this.freight_charge.emit(this.customerForm.get('freightcost').value);

		if (this.customer.customerpoline === true || this.customer.customerpoline === '1') {
			this.customerForm.get('reference').setValidators([Validators.required])
			// this.customerForm.get('reference').updateValueAndValidity();
			if (ref != '') {
				this.customerForm.get('reference').setValue(ref);
			}
		} else {

		}


		this.globalSearchService.shipvia.subscribe((results: any) => {
			if (results !== null) {
				this.shipvia = results;
				const localshipvia = results.filter(v => {
					return v.shipper_id == this.customer.defaultshipvia
				})[0]

				if (localshipvia) {
					if (this.init_editing['freightcost'] == '') {
						this.customerForm.get('freightcost').setValue(localshipvia.mincharge);
						this.freight_charge.emit(localshipvia.mincharge);
					}
				}
			}

		});

		this.customerForm.get('name').disable();
		this.customerForm.get('address1').disable();
		this.customerForm.get('address2').disable();
		this.customerForm.get('address3').disable();
		this.customerForm.get('address4').disable();
		this.customerForm.get('address5').disable();
		this.customerForm.get('address6').disable();

		this.customerForm.updateValueAndValidity();

		this.customerForm.valueChanges.subscribe(selectedValue => {
			this.updateCustomer.emit(this.customerForm);
		})

		this.customerForm.get('datewanted').valueChanges.subscribe(newValue => {
			if (this.noteselector.value == 2) {
				if (this.date_selected) {
					const parsed_date = this.getFormattedDate(newValue)
					const new_selection = 'Wanted ' + parsed_date;

					if (this.date_selected) {
						let currentComment = this.customerForm.get('comments').value;
						if (currentComment === null) {
							currentComment = '';
						}
						currentComment = currentComment.replace(this.date_selected, '').trim()
						this.customerForm.get('comments').setValue(new_selection + '\n' + currentComment);
						this.date_selected = 'Wanted ' + parsed_date;
					}
				}
			}
		});

		this.customerForm.get('defaultshipvia').valueChanges.subscribe(newValue => {
			const selected = this.shipvia.filter(v => {
				return v.shipper_id == newValue
			})[0]

			if (selected) {
				this.customerForm.get('freightcost').setValue(selected.mincharge);
				this.freight_charge.emit(selected.mincharge);
			}
		})

		if(this.config.features.vehicles){
			this.getCustomerVehicles();
		}


	}
	updateShipping() {

		if (this.customer.editingorder) {
			this.customer.editingorder.freightcost = this.customerForm.get('freightcost').value;
		}

		setTimeout(() => {
			this.freight_charge.emit(this.customerForm.get('freightcost').value);
			this.updateCustomer.emit(this.customerForm);
		}, 800);

	}

	updatePrintButton() {

		if (this.customer.editingorder) {
			this.customer.editingorder.doNotPrint = this.doNotPrint.value;
		}
		this.customerForm.get('doNotPrint').setValue(this.doNotPrint.value);
		this.updatePrint.emit(this.doNotPrint.value);
	}

	repriceCart(event: any) {
		if (event.value != this.customer.salestype) {
			this.customer.salestype = event.value
			const data = {
				debtorno: this.customer.debtorno,
				branchcode: this.customer.branchcode,
				salestype: event.value,
			}
			this.orderService.repriceCart(data).subscribe((result: any) => {
				this.sendCartUpdate();
			});
		}
	}

	toggleEditShipping() {
		this.editshipping = (this.editshipping) ? false : true;
	}

	toggleEditBilling() {
		this.editbilling = (this.editbilling) ? false : true;
	}

	toggleEditSettings() {
		this.editsettings = (this.editsettings) ? false : true;
	}

	updatePrice(event: any, item: any, recalc ? : any) {
		if (event.target.value != '') {
			const index = this.cart_items.indexOf(item);
			this.cart_items[index].price = event.target.value;
			event.stopPropagation();
			this.updateCartItem(this.cart_items[index]);
			if (recalc == true) {
				//this.recalculateCoupon();
			}
		}

	}



	updateQuantity(event: any, item: any, recalc ? : any) {
		let amt = event.target.value;

		// Clear any existing timeout to prevent multiple triggers
		clearTimeout(item.debounceTimer);

		// Set up debounce logic (e.g., 300ms)
		item.debounceTimer = setTimeout(() => {
			// Proceed only if the input is not empty or a space
			if (amt.trim() !== '' && amt.trim() !== ' ' && !isNaN(amt)) {
				amt = parseFloat(amt);

				if (amt < 0 && this.config.env.package !== 'beauty') {
					amt = 1;
				}

				const index = this.cart_items.indexOf(item);

				// Only update the quantity if it respects the invoiced quantity
				if (!this.cart_items[index].qtyinvoiced || this.cart_items[index].qtyinvoiced == 0 || amt >= this.cart_items[index].qtyinvoiced) {
					this.cart_items[index].quantity = amt;

					this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
					this.updateCartItem(this.cart_items[index]);

					if (recalc === true) {
						//this.recalculateCoupon();
					}
				} else {
					// Revert the value and notify user if quantity is below invoiced amount
					event.target.value = this.cart_items[index].quantity;
					this.globalSearchService.showNotification('Cannot reduce below already invoiced amount', 'danger', 'bottom', 'left');
				}
			}
		}, 400);
	}



	getDiscounPrice(item: any) {
		const discountprice = this.financial((item.price * (item.discount / 100)));
		const total = this.financial(item.price - discountprice);
		// if (total < 0) {
		// 	total = 0;
		// }
		return total;
	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	getDiscountTotal(item: any) {

		let discounted = (item.price * (item.discount / 100)).toFixed(2);
		let discountprice = (item.price - parseFloat(discounted))
		let total = this.financial((item.price - (item.price * (item.discount / 100))) * item.quantity);

		if (item.stockid !== 'COUPON') {
			discounted = (item.price * (item.discount / 100)).toFixed(2);
			discountprice = (item.price - parseFloat(discounted))
			total = this.financial((item.price - (item.price * (item.discount / 100))) * item.quantity);
			// allowing negative
			// if (total < 0 ) {
			// 	total = 0;
			// }

		} else {

			if (item.discount > 0) {
				discounted = (item.price * (item.discount / 100)).toFixed(2);
				discountprice = (item.price - parseFloat(discounted))
				total = this.financial((item.price - (item.price * (item.discount / 100))) * item.quantity);
			} else {

				const tot = (item.price * item.quantity).toFixed(2);
				const totprice = parseFloat(tot);
				total = totprice;

			}

		}

		return total;

	}

	updateDiscount(event: any, item: any) {

		if (event.target.value != '') {
			if (parseFloat(event.target.value) < 100) {

			} else {
				event.target.value = 100;
			}
			const index = this.cart_items.indexOf(item);
			this.cart_items[index].discount = event.target.value;
			// if(item['coupon_used'] != '' && this.config.env.package == 'beauty'){
			// 	this.removeCoupon(item);
			// }
			this.updateCartItem(this.cart_items[index]);
		}
	}


	massUpdateDiscount(event) {
		var discount;
		if (event.target.value != '') {
			if (isNaN(Number(event.target.value))) {
				event.target.value = '';
			} else if (parseFloat(event.target.value) > 100) {
				event.target.value = 100;
			}
			discount = event.target.value
		} else {
			discount = 0;
			event.target.value = ''
		}

		this.cart_items.map((item) => {
			if(!item.parent_id){
				item.discount = discount;
				this.updateCartItem(item);
			}
		})

		discount > 0 ? this.discountAllRef = discount : this.discountAllRef = ''
	}



	updateNote(event: any, item: any) {
		const index = this.cart_items.indexOf(item);
		this.cart_items[index].notes = event.target.value;
		this.updateCartItem(this.cart_items[index]);
	}

	updateCartItem(item: any) {


		//let index = this.cart_items.indexOf(item);


		const data = {
			customer: this.customer.debtorno,
			branch: this.customer.branchcode,
			cartid: item.cart_id,
			cart_id: item.cart_id,
			price: item.price,
			option: item.options,
			quantity: item.quantity,
			discount: item.discount,
			notes: item.notes,
			coupon_used: item.coupon_used,
			taxdetails: item.taxdetails,
			//editing_order: this.editing_order,
			//order_details: this.order_details,
		};

		this.updateCart.emit(data);

	}


	removeFromOrder(cart_id) {
		this.removeItem.emit(cart_id)
	}

	sendCartUpdate() {
		this.updateCart.emit(true);
	}

	ngOnChanges(changes: any) {

		if (changes.customer) {
			this.loadData();
		}

		if (this.config.orderadjustments) {
			if (changes.cart_items) {
				this.additionalcharge = this.charges.additionalcharge;
				this.additionaltext = this.charges.additionaltext;
				this.adjustRef.setValue(this.charges.additionalcharge);
				this.adjustTitleRef.setValue(this.charges.additionaltext);
			}
			if (changes.cart_totals && this.config?.orderadjustments) {
				this.updateAdj();
			}
		}

		if (this.customer.editingorder) {

			this.customerForm.get('branchcode').setValue(this.customer.editingorder.branchcode)

			this.init_editing = {
				dnote: this.customer.editingorder.notes,
				inote: this.customer.editingorder.comments,
				ref: this.customer.editingorder.customerref,
				shipvia: this.customer.editingorder.shipvia,
				freightcost: this.customer.editingorder.freightcost,
				trackingnumber: this.customer.editingorder.trackingnumber
			}

			if (!this.original_loaded) {
				this.customerForm.get('comments').setValue(this.customer.editingorder.notes);
				this.customerForm.get('notes').setValue(this.customer.editingorder.comments);
				this.customerForm.get('defaultshipvia').setValue(this.customer.editingorder.shipvia);
				this.customerForm.get('freightcost').setValue(this.customer.editingorder.freightcost);
				this.customerForm.get('reference').setValue(this.customer.editingorder.customerref);
				this.customerForm.get('trackingnumber').setValue(this.customer.editingorder.trackingnumber);
				this.freight_charge.emit(this.customer.editingorder.freightcost);
				this.updateCustomer.emit(this.customerForm);
				this.original_loaded = true;
			}

			this.customer.brname = this.customer.editingorder.brname;
			this.customer.deliverto = this.customer.editingorder.deliverto;
			this.customer.branchcode = this.customer.editingorder.branchcode;

			this.customer.braddress1 = this.customer.editingorder.deladd1;
			this.customer.braddress2 = this.customer.editingorder.deladd2;
			this.customer.braddress3 = this.customer.editingorder.deladd3;
			this.customer.braddress4 = this.customer.editingorder.deladd4;
			this.customer.braddress5 = this.customer.editingorder.deladd5;
			this.customer.braddress6 = this.customer.editingorder.deladd6;
			this.customer.contactname = this.customer.editingorder.deliverto;
			this.customer.shipto = this.customer.editingorder.shipto;

			this.customerForm.get('brname').setValue(this.customer.editingorder.deliverto);
			this.customerForm.get('braddress1').setValue(this.customer.editingorder.deladd1)
			this.customerForm.get('braddress2').setValue(this.customer.editingorder.deladd2)
			this.customerForm.get('braddress3').setValue(this.customer.editingorder.deladd3)
			this.customerForm.get('braddress4').setValue(this.customer.editingorder.deladd4)
			this.customerForm.get('braddress5').setValue(this.customer.editingorder.deladd5)
			this.customerForm.get('braddress6').setValue(this.customer.editingorder.deladd6);
			this.customerForm.get('email').setValue(this.customer.editingorder.contactemail);
			this.customerForm.get('phoneno').setValue(this.customer.editingorder.contactphone);

			this.customerForm.get('salesman').setValue(this.customer.editingorder.salesperson);

			if (this.user) {
				if (this.customer.editingorder.fromstkloc != this.user.defaultlocation.loccode && this.customer.editingorder.fromstkloc) {
					this.customerForm.get('defaultlocation').setValue(this.customer.editingorder.fromstkloc);
				}
			}

			if (this.customer.preselected) {
				this.customerForm.get('payment_method').setValue(this.customer.preselected);
				this.attachCard(this.customer.preselected);
			}

			if (!this.config.reprintorderedit && !this.issalesman) {
				this.doNotPrint.setValue(true);
			}



		} else {

		}

		if (this.config.env.package == 'beauty' && (this.recalculated == false || this.cart_items !== this.historic_cart)) {
			this.historic_cart = this.cart_items;
			//this.recalculateCoupon();
		}

		if(changes.vehicle){
			if(changes.vehicle.previousValue == false && changes.vehicle.currentValue){
				
				this.setVehicleForm();
			}
		}

		this.updateCustomer.emit(this.customerForm);
	}


	/**
	 * Helper function to return date
	 * @returns Current UTC date in YYYY/MM/DD
	 */
	getTimeFormat(): string {
		const cTime = new Date();

		const month = (((cTime.getUTCMonth() + 1) < 10) ? '0' : '') + (cTime.getUTCMonth() + 1);
		const day = ((cTime.getUTCDate() < 10) ? '0' : '') + cTime.getUTCDate();
		const year = cTime.getUTCFullYear();

		return year + '/' + month + '/' + day;
	}

	applyCoupon(input: any) {
		const code = this.couponFormControl.value;

		const data = {
			code: code,
			customer: this.customer,
			user: this.user.userid,
			cart: this.cart_items
		}

		this.couponData == false ? this.couponData = {} : '';

		this.couponService.applyCoupon(data).subscribe((result: any) => {

			if (result['status']) {
				this.globalSearchService.showNotification(result.message, 'success', 'bottom', 'left');
				this.couponData[code] = { data: result['data'], items: result['prods'], categories: result['cats'] };
				this.coupon = code;
				this.refreshCart.emit();

			} else {
				this.globalSearchService.showNotification(result.message, 'danger', 'bottom', 'left');
				return;
			}
		});

	}

	recalculateCoupon() {
		const items = [];
		const coupons = [];
		(this.cart_items).forEach(item => {
			const data = {
				stockid: item.stockid,
				quantity: item.quantity,
				category: item.categoryid,
				price: item.price
			}
			item['stockid'] == 'COUPON' ? coupons.push(item) : items.push(data);
		});
		if (coupons.length == 0) {
			return;
		}

		const data = {
			items: items,
			coupons: coupons,
			customer: this.customer['debtorno'],
			branch: this.customer['branchcode']
		}

		const activeCouponData = {};

		this.couponService.recalculateCoupons(data).subscribe((result: any) => {
			result.forEach(item => {

				activeCouponData[item.data.code] == undefined ? activeCouponData[item.data.code] = item : '';
				const cart_item = this.cart_items.filter((i) => i.cart_id == item.line.cart_id)[0];

				const index = this.cart_items.indexOf(cart_item);

				const event = { target: { value: '' } };

				if (item.line.discount !== 'invalid') {
					event.target.value = this.financial(item.line.price) + '';
					this.updatePrice(event, cart_item, false);
					this.cart_items[index].validity = 'valid';

				} else {
					event.target.value = '0';
					this.updatePrice(event, cart_item, false);
					this.cart_items[index].validity = item.line.discount;
				}


			});
			this.couponData = activeCouponData;
			this.recalculated = true;
		});

	}

	removeCoupon() {
		const data = {
			customer: this.customer,
			code: this.coupon,
		}
		this.couponService.unapplyCoupon(data).subscribe((result: any) => {
		this.globalSearchService.showNotification(result.message, 'warning', 'bottom', 'left');
			this.coupon = false;
			this.refreshCart.emit();
		});
	}
	
	checkCoupon(){
	
		const data = {
			customer: this.customer
		}
		this.couponService.checkForCoupon(data).subscribe((result: any) => {
			this.coupon = result.code;
		});
	
	}


	isCouponFixed(code: any) {
		const type = this.couponData[code].data.type == 0 ? '$' : '';
		return type;
	}

	isCouponPercent(code: any) {
		const type = this.couponData[code].data.type == 1 ? '%' : '';
		return type;
	}

	ngAfterViewInit() {

	}

	updateTax(item: any) {
		this.itemdetails = item;
		this.modalService.open(this.taxdetailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {
			this.itemdetails = false;
			this.refreshCart.emit(true);
		});

	}

	updateTaxRate(newvalue: any, tax: any) {
		//rounding on the other side
		const newtaxvalue = newvalue / 100;
		const index = this.itemdetails.taxdetails.indexOf(tax);

		this.itemdetails.taxdetails[index].taxrate = this.santizeNumberValue(newtaxvalue)
		this.itemdetails.taxdetails[index].taxratepercent = this.santizeNumberValue(newvalue)

		this.updateCartItem(this.itemdetails);


	}

	santizeNumberValue(input: any) {
		return (isNaN(input) || input == '') ? 0 : parseFloat(input);
	}

	decimalVal(x) {
		return parseFloat(Number.parseFloat(x).toFixed(5));
	}

	getTaxDisplay(amount: any) {

		if (amount && !isNaN(amount)) {
			return this.decimalVal(amount * 100);
		}

		return 0.00
	}

	selectText(event: FocusEvent) {
		const input = event.target as HTMLInputElement;
		if (input) {
			setTimeout(() => {
				input.select();
			}, 0);
		}
	}

	getCustomerVehicles(){
		const data = {
			debtorno : this.customer.debtorno,
			branchcode: this.customer.branchcode
		}
		this.customerService.getVehicles(data).subscribe((res)=>{
			this.custVehicles = res;
		})
	}

	//add output from tireguide form into vehicle form
	//used for past vehicle selection as well (changes tab back to 0 on select)
	updateVehicleForm(event, changeTab = false){
		const dates = ['next_maint_date', 'next_inspection_date', 'next_oil_change'];
		Object.keys(event).forEach(k=>{
			if(dates.includes(k)){
				event[k] = new Date(event[k]);
			}
			this.vehicleDetsForm.get(k)?.setValue(event[k]);
		})
		if(changeTab){
			this.vehicle_tab = 0;
			this.editing_vehicle = event;
		}
		this.resetTireGuide = false;
	}

	//open vehicle form and emit form on modal close - adds debtorno and branch to form for easy db entry
	openVehicleForm(){
		this.vehicleModalOpen = true;
		this.modalService.open(this.vehicleModalRef, { ariaLabelledBy: 'modal-title', size: 'xl', backdrop: 'static', keyboard: false}).result.then((result: any) => {

		}, (reason) => {
			this.vehicleModalOpen = false;
			this.vehicleDetsForm.get('debtorno').setValue(this.customer.debtorno)
			this.vehicleDetsForm.get('branch').setValue(this.customer.branchcode)
			this.updateVehicle.emit(this.vehicleDetsForm)
		});
	}

	//initialization of vehicleform
	setVehicleForm(){
		if(this.vehicle?.value){
			this.vehicle = this.vehicle.value;
		}
		if(this.resetTireGuide){
			this.vehicleDetsForm.reset();
		}else{
			this.vehicleDetsForm = this.fb.group({
				year: [this.vehicle?.year ? this.vehicle.year : '', [Validators.required]],
				make: [this.vehicle?.make ? this.vehicle.make : '', [Validators.required]],
				model: [this.vehicle?.model ? this.vehicle.model : '', []],
				model_type: [this.vehicle?.model_type ? this.vehicle.model_type : '', [Validators.required]],
				vin: [this.vehicle?.vin ? this.vehicle.vin : '', [Validators.required]],
				plate: [this.vehicle?.plate ? this.vehicle.plate : '', [Validators.required]],
				serial: [this.vehicle?.serial ? this.vehicle.serial : '', [Validators.required]],
				engine_size: [this.vehicle?.engine_size ? this.vehicle.engine_size : ''],
				transmission: [this.vehicle?.transmission ? this.vehicle.transmission : ''],
				last_mileage: [this.vehicle?.last_mileage ? this.vehicle.last_mileage : ''],
				annual_mileage: [this.vehicle?.annual_mileage ? this.vehicle.annual_mileage : ''],
				next_inspection_date: [this.vehicle?.next_inspection_date ? new Date(this.vehicle.next_inspection_date) : ''],
				next_maint_date: [this.vehicle?.next_maint_date ? new Date(this.vehicle.next_maint_date) : ''],
				next_oil_change: [this.vehicle?.next_oil_change ? new Date(this.vehicle.next_oil_change) : ''],
				notes: [this.vehicle?.notes ? this.vehicle.notes : ''],
				debtorno: [this.customer.debtorno],
				branch: [this.customer.branchcode]
			});

			if(this.vehicle?.plate && this.vehicle?.serial && this.vehicle?.vin ){
				this.editing_vehicle = this.vehicleDetsForm.value;
			}
		}
		
		this.getTGuideInput()
	}

	resetTg(){
		this.resetTireGuide = true;
		this.setVehicleForm()
	}

	//data to rebuild tireguid form - on reopen / clearing
	//resetTireGuide var is set by clear button in modal, is set back to false when output is emitted from tireguide
	getTGuideInput() {
		const make = this.vehicleDetsForm.get('make').value
		const year = this.vehicleDetsForm.get('year').value
		const model = this.vehicleDetsForm.get('model').value
		const model_type = this.vehicleDetsForm.get('model_type').value
		if (make != '' && year != '' && model != '' && model_type != '' && !this.resetTireGuide) {
			return {
				make: make,
				year: year,
				model: model,
				model_type: model_type
			}
		} else {
			return this.resetTireGuide ? "reset" : false;
		}
	}

	vehicleFormEmpty(){
		const keys = ['year', 'make', 'model', 'model_type', 'vin','serial','plate']
		var is_empty = true;
		const obj = this.vehicleDetsForm.value

		keys.forEach(k => {
			if(is_empty){
				is_empty = obj[k] === null || obj[k]== '' ? true : false
			}
		});
		return is_empty;
	}

	assignVehicle(){
		this.modalService.dismissAll();
	}
}