import { ElementRef, Component, Output, EventEmitter, ViewChild, AfterViewInit, OnInit} from '@angular/core';

@Component({
  selector: 'app-electric-signature',
  templateUrl: './electric-signature.component.html',
  styleUrls: ['./electric-signature.component.scss']
})

export class ElectricSignatureComponent implements AfterViewInit, OnInit{
  @ViewChild('signaturePad') signPadRef: ElementRef;
  @Output() signatureEmit = new EventEmitter<any>();
  pos: any = false;
  context: any = false; //canvas
  signing: boolean = false; //mousedown = true

  constructor(){
    this.pos = { x: 0, y: 0 };
  }

  ngOnInit(): void {
    //Need this to keep canvas offsets in sync on resize
    window.addEventListener('resize', ()=>{
      setTimeout(()=>{
        this.getCanvas()
      }, 1000)

    });
  }

  ngAfterViewInit(): void {
    //need this otherwise bounds are incorrect
    setTimeout(()=>{
      this.getCanvas()
    }, 1000);
  }

  getCanvas(){
    this.signPadRef.nativeElement.width = this.signPadRef.nativeElement.offsetWidth
    this.signPadRef.nativeElement.height = this.signPadRef.nativeElement.offsetHeight
    this.context = this.signPadRef.nativeElement.getContext('2d');
    this.drawGuideLine();
  }

  
  draw(event){
    if(this.signing){
      this.context.lineWidth = 1;
      this.context.linecap = 'round';
      this.context.strokeStyle = '#ff1e1e';
      this.context.beginPath();
      this.context.moveTo(this.pos.x, this.pos.y);
      this.setPosition(event);
      this.context.lineTo(this.pos.x, this.pos.y)
      this.context.stroke();
    }
  }

  setPosition(event: any){
    const canvas_pos = this.signPadRef.nativeElement.getBoundingClientRect(); 
    this.pos.x =  event.clientX - canvas_pos.left;
    this.pos.y =  event.clientY - canvas_pos.top
  }

  drawmode(active){
    this.signing = active;
  }

  clear(){
    this.context.clearRect(0, 0, this.signPadRef.nativeElement.width, this.signPadRef.nativeElement.height);
    this.drawGuideLine();
  }

  captureCanvas(){
    const dataurl = this.rasterizeCanvas();
    this.signatureEmit.emit(dataurl);
    this.clear();
  }

  rasterizeCanvas(){
    const ref = this.signPadRef.nativeElement as HTMLCanvasElement;
    return ref.toDataURL('image/jpeg', 100);
  }

  drawGuideLine(){
    const w = this.signPadRef.nativeElement.width
    const h = this.signPadRef.nativeElement.height
    const start = {x: (w * .10), y: h-45}
    const end = {x: (w - start.x), y: h-45}
    const x1_start = {x:start.x + 12, y: start.y-5}
    const x1_end =   {x:start.x, y: x1_start.y-12}
    const x2_start = {x:start.x, y: start.y-5}
    const x2_end =   {x:start.x + 12, y: x1_start.y-12}
    
    this.context.lineWidth = 1;
    this.context.linecap = 'round';
    this.context.strokeStyle = '#343434';

    this.context.beginPath();
    this.context.moveTo(x1_start.x, x1_start.y)
    this.context.lineTo(x1_end.x, x1_end.y)
    this.context.stroke();

    this.context.beginPath();
    this.context.moveTo(x2_start.x, x2_start.y)
    this.context.lineTo(x2_end.x, x2_end.y)
    this.context.stroke();

    this.context.strokeStyle = 'gray';

    this.context.setLineDash([2, 6]);
    this.context.lineDashOffset = 4;
    
    this.context.beginPath();
    this.context.moveTo(start.x, start.y);
    this.context.lineTo(end.x, end.y)
    this.context.stroke();

    this.context.setLineDash([0,0])
  }



}
